import gql from 'graphql-tag';
import { ListNames } from '@components/dataEntry/AdvancedSearchBar/RelatedAreas/RelatedAreas';

export const globalAreaId = 0;

export type ActiveOrder = {
  clientId: number;
  area: {
    id: number;
    name: string;
    fullName?: string;
    city?: string;
    country?: string;
    areaType?: string;
  };
};

export enum PlanTypeEnum {
  PRO = 'PRO',
  BASIC = 'BASIC',
  FREE = 'FREE',
}

export enum PlanScopeEnum {
  MARKET = 'MARKET',
  STATE = 'STATE',
  COUNTRY = 'COUNTRY',
  GLOBAL = 'GLOBAL',
}

export enum PeriodEnum {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export type PlanType = keyof typeof PlanTypeEnum;

export type PlanScope = keyof typeof PlanScopeEnum;

export type PeriodType = PeriodEnum.YEARLY | PeriodEnum.MONTHLY;

export type Areas = {
  areaId: number;
  isSampleArea?: boolean;
  areaName: string;
  planId: string;
  planType: PlanType;
  planName: string;
  planScope: PlanScope;
  containedAreas?: Areas[];
  city?: string;
  country?: string;
  areaType?: string;
  firstTargetDate?: string;
  position?: number;
  listName?: ListNames;
  planInterval?: string;
  defaultAreaId: number;
};

export type PlanLimit = {
  planType: PlanType;
  userListingsLimit: number;
  competitiveListingsLimit: number;
  historicalDataMonthsLimit: number;
  futureDataMonthsLimit: number;
};

export type ActiveOrderV2 = {
  clientId: number;
  areas: Areas[];
  planLimits: PlanLimit[];
};

export type ActiveOrderV2Query = {
  activeOrdersV2: ActiveOrderV2;
};

export type ActiveOrders = {
  activeOrders: ActiveOrder[];
};

export const GET_ACTIVE_ORDERS = gql`
  query getActiveOrders {
    activeOrders {
      clientId
      area {
        id
        name
        fullName
      }
    }
  }
`;

export const GET_ACTIVE_ORDERS_V2 = gql`
  query getActiveOrders {
    activeOrdersV2 {
      clientId
      areas {
        areaId
        areaName
        planId
        planType
        planName
        planScope
        isSampleArea
        city
        country
        firstTargetDate
        planInterval
        defaultAreaId
        areaType
        containedAreas {
          areaId
          areaName
          planId
          planType
          planName
        }
      }
      planLimits {
        planType
        userListingsLimit
        competitiveListingsLimit
        historicalDataMonthsLimit
        futureDataMonthsLimit
      }
    }
  }
`;
