import { useState, useEffect } from 'react';
import { useWindowSize } from '@hooks/useWindowSize';

const desktop = 1280;
const tablet = 700;

const getDeviceConfig = (width: number) => {
  if (width < tablet) return 'mobile';
  if (width >= tablet && width < desktop) return 'tablet';
  return 'desktop';
};

const useBreakpoint = () => {
  const windowsObject = useWindowSize();
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(windowsObject.width));
  useEffect(() => {
    setBrkPnt(getDeviceConfig(windowsObject.width));
  }, [windowsObject.width]);
  return brkPnt;
};

export default useBreakpoint;
