/* eslint-disable max-len */
import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { useUser } from '@hooks/useUser';
import { useLogin } from '@hooks/useLogin';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { DropdownBox } from '@components/navigation/DropdownBox/DropdownBox';
import { Rate } from '@hooks/useCurrency';
import { CONTACT_US_WEBAPP } from '@utils/links';
import { CreditCardIcon } from '@components/iconography/CreditCardIcon/CreditCardIcon';
import { LogoutIcon } from '@components/iconography/LogoutIcon/LogoutIcon';
import { ContactUsIcon } from '@components/iconography/ContactUsIcon/ContactUsIcon';
import { SignOutButton } from '@clerk/nextjs';

import styles from './UserMenu.module.scss';

type Props = {
  currency?: Rate & {
    onClick?: () => void;
  };
  dataTestId?: string;
};

export const UserMenu: React.FC<Props> = ({ currency, dataTestId }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const { isLogin, user } = useUser();
  const { logIn, logOut } = useLogin();
  const { isGodmodeOrHasGlobalPlan } = useAreaActiveOrdersV2();
  const ref = React.useRef<HTMLAnchorElement>(null);
  const isPaidUser = user?.isPremiumUser || isGodmodeOrHasGlobalPlan;

  const onClickOut = () => setShowMenu(false);

  const signOutCallback = () => {
    setShowMenu(false);
    logOut();
  };

  const onClick = () => {
    if (isLogin) setShowMenu(!showMenu);
    if (!isLogin) logIn();
  };

  const callOnClickCurrency = () => {
    if (currency?.onClick) {
      currency.onClick();
      setShowMenu(false);
    }
  };

  return (
    <div className={styles.UserMenuContainer}>
      <a
        ref={ref}
        className={clsx(
          styles.UserMenu,
          { [styles.UserMenuLogin]: isLogin },
          showMenu && styles.Active,
          !isLogin && styles.UserMenuLogOut,
        )}
        onClick={onClick}
        data-test-id={dataTestId}
      >
        <div className={clsx(isLogin && styles.IconContainer, !isPaidUser && styles.NoBadge)}>
          {isLogin && <img src="/images/profile.svg" alt="user" width="14" height="16.8" className={styles.UserIcon} />}
          {isLogin && isPaidUser && (
            <img className={styles.PremiumBadge} src="/images/icons/premium-badge.svg" alt="♕" />
          )}
        </div>
        <span className={styles.SignInLabel}>Sign in</span>
      </a>
      <DropdownBox className={styles.MenuContainer} isOpen={showMenu} onClickOut={onClickOut} target={ref}>
        <ul className={styles.Menu}>
          <li>
            {user && (
              <div className={styles.UserInfo}>
                <div className={styles.UserName}>
                  <span className={styles.Name}>{user.name}</span>
                  <span className={styles.Email}>{user.email}</span>
                </div>
                <a className={styles.UserType} href="/account-settings?tab=account">
                  {isPaidUser ? 'Premium User' : 'Free Account'}
                </a>
              </div>
            )}
          </li>
          <li>
            <Link href="/account-settings?tab=subscriptions">
              <CreditCardIcon className={styles.CreditCardIcon} />
              Subscriptions
            </Link>
          </li>

          {user && !!currency && (
            <li>
              <a onClick={callOnClickCurrency}>
                <div data-test-id="initial-currency-mobile" className={styles.SymbolCurrency}>
                  {currency.symbol}
                </div>
                {currency.code}
              </a>
            </li>
          )}
          <li>
            <a href={CONTACT_US_WEBAPP}>
              <ContactUsIcon className={styles.ContactIcon} stroke={styles.stroke} fill={styles.fill} />
              Contact Us
            </a>
          </li>
          <li>
            <SignOutButton signOutCallback={signOutCallback}>
              <a>
                <LogoutIcon stroke={styles.stroke} fill={styles.fill} />
                Log Out
              </a>
            </SignOutButton>
          </li>
        </ul>
      </DropdownBox>
    </div>
  );
};
