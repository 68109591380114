import * as React from 'react';
import clsx from 'clsx';
import { Styles, StyleTheme } from 'src/style/Styles';

import styles from './Box.module.scss';

export interface Props extends Styles {
  padding?: 'none' | 'small' | 'big';
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: () => void;
  removeBg?: boolean;
}

export const Box = React.forwardRef<HTMLDivElement, Props>(
  ({ padding, className, style, children, onClick, removeBg, ...props }, ref) => (
    <div
      style={{ ...StyleTheme(props), ...style }}
      onClick={onClick}
      ref={ref}
      className={clsx(
        styles.Box,
        {
          [styles.BoxStyleSmall]: padding === 'small',
          [styles.BoxStyleBig]: padding === 'big',
        },
        className,
      )}
    >
      {children}
    </div>
  ),
);
