import { ActiveOrder } from '@apolloCli/queries/activeOrders';
import { FollowArea } from '@apolloCli/queries/followAreas';
import { ScoresType } from '@apolloCli/queries/listings';

const AREA_ORDER = {
  radius: 0,
  postal_code: 1,
  postalcode: 1,
  neighborhood: 2,
  city: 3,
  county: 4,
  state: 5,
};

export const orderAreasAlphabetical = (array: ActiveOrder[] | FollowArea[]) =>
  array.slice().sort((a, b) => (b.area.name.localeCompare(a.area.name) !== 1 ? 1 : -1));
export const orderScores = (scores: ScoresType[]) => {
  const sortedByArea = [...scores].sort((a, b) =>
    (AREA_ORDER as any)[a.areaType] > (AREA_ORDER as any)[b.areaType] ? 1 : -1,
  );
  return sortedByArea;
};
