import { Notifications, NotificationItem } from '@interfaces/UI';
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from '@apolloCli/queries/ui';
import { notificationsVar, notificationPositionBottomLeftVar } from '@apolloCli/policies/uiPolicy';

type AddNotificationsProps = Pick<NotificationItem, 'title' | 'type' | 'description'> & {
  id?: number;
  expires?: number;
};

const defaultExpires: { [index in NotificationItem['type']]: number } = {
  error: 5000,
  warning: 3000,
  info: 2000,
  success: 5000,
};

export const removeNotifications = (notificationId: number) => {
  notificationsVar(notificationsVar().filter((noti) => noti.id !== notificationId));
};

export const addNotification = ({ title, type, description, expires }: AddNotificationsProps) => {
  const newNotification = {
    id: Date.now(),
    title,
    description,
    type,
  };

  notificationsVar([...notificationsVar(), newNotification]);
  const removeCallback = () => {
    notificationPositionBottomLeftVar(false);
    removeNotifications(newNotification.id);
  };
  setTimeout(removeCallback, expires || defaultExpires[type]);
};

export const useUiNotifications = () => {
  const { data } = useQuery<Notifications>(GET_NOTIFICATIONS);
  const notifications = data?.notifications || notificationsVar();

  return {
    notifications,
  };
};
