import clsx, { ClassValue } from 'clsx';
import React from 'react';

import styles from './ContactUsIcon.module.scss';

type IconProps = {
  className?: ClassValue;
  stroke?: ClassValue;
  fill?: ClassValue;
};

export const ContactUsIcon: React.FC<IconProps> = ({ className, stroke, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.222" height="14.255" className={clsx(styles.Icon, className)}>
    <path
      d="M.65 6.802C.65 3.405 3.08.65 6.078.65s5.428 2.755 5.428 6.152c0 3.559-2.523 6.8-5.428 6.8S.65 10.361.65 6.802z"
      fill="none"
      strokeWidth="1.3"
      className={clsx(styles.stroke, stroke)}
    />
    <path
      d="M6.712 10.533h6.01"
      fill="none"
      strokeLinecap="square"
      strokeLinejoin="round"
      className={clsx(styles.stroke, stroke)}
    />
    <path
      // eslint-disable-next-line max-len
      d="M1.181 5.665s1.571 1.172 4.636 0a7.031 7.031 0 015.376 0s1.1-4.284-5.063-4.993-4.949 4.993-4.949 4.993zM12.215 7.223a1 1 0 011 1v2h-2v-2a1 1 0 011-1zM7.215 9.223a1 1 0 011 1h-2a1 1 0 011-1z"
      className={clsx(styles.fill, fill)}
    />
  </svg>
);
