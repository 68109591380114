import gql from 'graphql-tag';

export type MapListing = {
  uid: string;
  bedrooms: number;
  longitude: number;
  latitude: number;
  arrangementType:
    | 'Private Room'
    | 'Shared Room'
    | 'Entire Home'
    | 'Hotel'
    | 'studio'
    | '1b'
    | '2b'
    | '3b'
    | 'anyB'
    | null;
};

export type ListingImageType = {
  n?: string;
  caption?: string;
}[];

export type HistoryScoresType = {
  value: number;
  month: string;
}[];

export type ScoresType = {
  areaId: number;
  score: number;
  difference: number;
  description: string[];
  areaType: string;
  name?: string;
};

export type VrpsHistoryType = {
  areaId: number;
  areaType: string;
  scores: HistoryScoresType;
};

export type Listing = MapListing & {
  areaId: number;
  providerId: string;
  name: string;
  sleeps?: number;
  url?: string;
  image?: {
    n: string;
    t?: string;
  };
  vrps: {
    value?: number;
    month: string;
  };
  // TODO add this missing options to the query
  isSuperhost?: boolean;
  bathrooms?: number;
  rooms?: number;
  beds?: number;
  areaName?: string;
  guests?: number;
  rating?: number;
  dailyRate?: number;
  occupancyRate?: number;
  trackedId?: number;
  reviewsCount?: number;
  grossRevenueLtm?: number;
  images: ListingImageType;
  hostName?: string;
  description?: string;
  amenities?: string[];
  childrenAllowed?: boolean;
  eventsAllowed?: boolean;
  smokingAllowed?: boolean;
  petsAllowed?: boolean;
  checkInTime?: string;
  checkOutTime?: string;
  cleaningFee?: number;
  weeklyDiscountFactor?: number;
  monthlyDiscountFactor?: number;
  vrpsHistory: VrpsHistoryType[];
  scores: ScoresType[];
};

export type GetListingDetails = {
  listing: Listing;
};

export type GetListingDetailsStats = {
  listingsDetailsStats: {
    details: {
      uid: string;
      datelessDeeplink: string;
      name: string;
      propertyType: string;
      arrangementType: string;
      longitude: string;
      latitude: string;
      city: string;
      nativeCurrency: string;
      rooms: string;
      bathrooms: string;
      maxCapacity: string;
      rating: string;
      petsAllowed: string;
      amenities: string;
      securityDeposit: string;
      createdAt: number;
      numberOfReviews: string;
      numberOfPhotos: string;
    }[];
    stats: {
      uid: string;
      datelessDeeplink: string;
      providerId: string;
      date: string;
      isActive: boolean;
      adr: string;
      nightsBooked: string;
      nightsAvailable: string;
      bookings: string;
      avgStayLength: string;
    }[];
  };
};

export const GET_LISTING_DETAILS = gql`
  query getListingDetails(
    $areaId: Int
    $uid: String!
    $providerId: String!
    $trackedUid: String
    $trackedProviderId: String
    $month: String!
  ) {
    listing(
      areaId: $areaId
      uid: $uid
      providerId: $providerId
      trackedUid: $trackedUid
      trackedProviderId: $trackedProviderId
      month: $month
    ) {
      name
      rating
      areaName
      areaId
      uid
      providerId
      name
      arrangementType
      instantBook
      isManaged
      latitude
      longitude
      url
      sleeps
      bedrooms
      bathrooms
      image {
        t
        n
      }
      vrps {
        value
        month
      }
      isSuperhost
      dailyRate
      occupancyRate
      trackedId
      rating
      reviewsCount
      beds
      images {
        n
        caption
      }
      hostName
      description
      amenities
      childrenAllowed
      eventsAllowed
      smokingAllowed
      petsAllowed
      checkInTime
      checkOutTime
      cleaningFee
      weeklyDiscountFactor
      monthlyDiscountFactor
      scores {
        areaId
        score
        difference
        description
        areaType
      }
      vrpsHistory {
        areaId
        areaType
        scores {
          value
          month
        }
      }
    }
  }
`;

export type GetListings = {
  listings: MapListing[];
};

export const GET_LISTING_BY_AREA = gql`
  query getListingByArea(
    $areaId: Int!
    $providerId: String!
    $startOfSinceMonth: String!
    $startOfUntilMonth: String!
    $arrangementTypes: String
    $instantBook: String
    $adrLtm: String
    $rooms: String
    $bathrooms: String
    $sleeps: String
    $hostType: String
    $rating: String
    $areaType: String!
    $nListings: Int
    $lastActiveSince: String
    $propertyType: String
    $amenities: String
    $houseRules: String
    $viewportBoundingBox: ViewportBoundingBoxInput
  ) {
    listings(
      areaId: $areaId
      providerId: $providerId
      since: $startOfSinceMonth
      rooms: $rooms
      bathrooms: $bathrooms
      sleeps: $sleeps
      until: $startOfUntilMonth
      arrangementTypes: $arrangementTypes
      instantBook: $instantBook
      adrLtm: $adrLtm
      hostType: $hostType
      propertyType: $propertyType
      amenities: $amenities
      rating: $rating
      areaType: $areaType
      houseRules: $houseRules
      nListings: $nListings
      lastActiveSince: $lastActiveSince
      viewportBoundingBox: $viewportBoundingBox
    ) {
      uid
      bedrooms
      longitude
      latitude
      arrangementType
    }
  }
`;

export type GetListingsByLocation = {
  listingsByLocation: MapListing[];
};

export const GET_LISTING_BY_LOCATION = gql`
  query getListingsByLocation(
    $latitude: Float!
    $longitude: Float!
    $radius: Int!
    $providerId: String!
    $arrangementTypes: String
    $hostType: String
    $rating: String
    $houseRules: String
    $rooms: String
    $bathrooms: String
    $sleeps: String
    $instantBook: String
    $adrLtm: String = "1+"
  ) {
    listingsByLocation(
      latitude: $latitude
      longitude: $longitude
      radius: $radius
      providerId: $providerId
      rooms: $rooms
      bathrooms: $bathrooms
      sleeps: $sleeps
      arrangementTypes: $arrangementTypes
      hostType: $hostType
      rating: $rating
      adrLtm: $adrLtm
      instantBook: $instantBook
      lastActiveSince: "30-"
      houseRules: $houseRules
    ) {
      uid
      bedrooms
      longitude
      latitude
      arrangementType
    }
  }
`;

export const GET_LISTINGS_DETAILS_STATS = gql`
  query getListingsDetailsStats(
    $areaId: Int!
    $providerId: String
    $arrangementTypes: String
    $rooms: String
    $bathrooms: String
    $sleeps: String
    $rating: String
    $instantBook: String
    $hostType: String
    $since: String
    $until: String
    $period: String
    $adrLtm: String
    $propertyType: String
    $amenities: String
    $houseRules: String
  ) {
    listingsDetailsStats(
      areaId: $areaId
      providerId: $providerId
      since: $since
      until: $until
      arrangementTypes: $arrangementTypes
      instantBook: $instantBook
      rooms: $rooms
      bathrooms: $bathrooms
      sleeps: $sleeps
      hostType: $hostType
      rating: $rating
      period: $period
      nListings: 1000
      adrLtm: $adrLtm
      lastActiveSince: "30-"
      propertyType: $propertyType
      amenities: $amenities
      houseRules: $houseRules
    ) {
      stats {
        uid
        datelessDeeplink
        providerId
        date
        isActive
        adr
        nightsBooked
        nightsAvailable
        bookings
        avgStayLength
      }
      details {
        uid
        datelessDeeplink
        name
        propertyType
        arrangementType
        longitude
        latitude
        city
        nativeCurrency
        rooms
        bathrooms
        maxCapacity
        rating
        petsAllowed
        amenities
        securityDeposit
        createdAt
        numberOfReviews
        numberOfPhotos
      }
    }
  }
`;

export const GET_LISTINGS_COUNT = gql`
  query getListingsCount(
    $areaId: Int!
    $provider: String
    $arrangementType: String
    $rooms: String
    $bathrooms: String
    $sleeps: String
    $instantBook: String
    $hostType: String
    $rating: String
    $adrLtm: String
    $propertyType: String
    $amenities: String
    $until: String
    $houseRules: String
    $viewportBoundingBox: ViewportBoundingBoxInput
  ) {
    listingsCount(
      areaId: $areaId
      providerId: $provider
      until: $until
      arrangementTypes: $arrangementType
      instantBook: $instantBook
      rating: $rating
      sleeps: $sleeps
      hostType: $hostType
      rooms: $rooms
      bathrooms: $bathrooms
      adrLtm: $adrLtm
      propertyType: $propertyType
      amenities: $amenities
      houseRules: $houseRules
      viewportBoundingBox: $viewportBoundingBox
    ) {
      listingCount
      listingCountActive
    }
  }
`;
