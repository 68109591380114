import React, { useState, useEffect, useRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Box } from '@components/layout/Box/Box';
import { Portal } from '@components/utils/Portal/Portal';

import styles from './PopOverMobile.module.scss';

type Props = {
  isOpen?: boolean;
  className?: ClassValue;
  afterOpen?: () => void;
  afterClose?: () => void;
  modalClassName?: ClassValue;
  childrenClassName?: ClassValue;
  children: React.ReactNode;
};

const addBodyClass = (className: string) => document.body.classList.add(className);
const removeBodyClass = (className: string) => document.body.classList.remove(className);

export const PopOverMobile: React.FC<Props> = ({
  isOpen,
  className,
  afterOpen,
  afterClose,
  children,
  modalClassName,
  childrenClassName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isPortal, setPortal] = useState(isOpen);
  const [isActive, setActive] = useState(isOpen);
  const [isCompleted, setIsCompleted] = useState(false);

  const closePopOver = () => {
    setActive(!isActive);
  };

  // onOpen: show portal and disable scroll
  useEffect(() => {
    if (isOpen) {
      addBodyClass('modal-active');
      setPortal(true);
    } else {
      setActive(false);
    }
    return () => removeBodyClass('modal-active');
  }, [isOpen]);

  // onPortalOpen: add listener and activate animation
  // onPortalClose:
  useEffect(() => {
    const onComplete = () => {
      setIsCompleted(true);
    };
    const box = ref.current;
    if (isPortal && box) {
      box.addEventListener('transitionend', onComplete);
      setTimeout(() => setActive(true), 1);
    }
    if (!isPortal && box) box.removeEventListener('transitionend', onComplete);
    return () => {
      if (box) box.removeEventListener('transitionend', onComplete);
    };
  }, [ref, isPortal]);

  // onActive && onFinishAnimation: call afterOpen()
  // onInactive && onFinishAnimation: enable scroll and turn off portal
  useEffect(() => {
    if (isCompleted) {
      if (!isActive) {
        removeBodyClass('modal-active');
        setPortal(false);
      }
      if (isActive && afterOpen) afterOpen();
      if (!isActive && afterClose) afterClose();
      setIsCompleted(false);
    }
  }, [isActive, isCompleted, afterOpen, afterClose]);

  return isPortal ? (
    <Portal>
      <div key="modal" className={clsx(styles.ModalContainer, modalClassName)}>
        <div className={styles.ModalBackground} onClick={closePopOver} />
        <Box ref={ref} className={clsx(styles.Box, isActive && styles.Active, styles.FadeInAnimation, className)}>
          <div className={clsx(styles.Content, childrenClassName)}>{children}</div>
        </Box>
      </div>
    </Portal>
  ) : null;
};
