import React from 'react';
import { EmailAuthStatus } from '@components/auth/EmailLogin/EmailLogin';

import { StepProps } from '../LoginWizard.interface';

import styles from './EmailLoginStatus.module.scss';

type EmailLoginStatusProps = StepProps & { status?: EmailAuthStatus };

export const EmailLoginStatus: React.FC<EmailLoginStatusProps> = ({ next, status }) => {
  const backToSignInOnClick = () => next({ step: 'signin' });

  return (
    <div className={styles.EmailLoginStatusContainer}>
      <img src="/images/mail-flying.svg" />
      <h3 className={styles.EmailTitle}>Check your E-mail!</h3>
      <p className={styles.MainText}>
        A magic link has been sent to <span className={styles.Email}>{status?.info?.email}</span>.
        <br />
        <br />
        <span className={styles.SecondTextParagraph}>
          Locate your magic link in the SSO email sent from vrps@alltherooms.com and click the link in that email to
          sign-in. (Be sure to check your SPAM folder too!😳)
        </span>
      </p>

      <div className={styles.GrayText}>Don’t have access to your E-mail?</div>
      <a className={styles.BackToLogin} onClick={backToSignInOnClick}>
        Back to Sign in options
      </a>
    </div>
  );
};
