import clsx, { ClassValue } from 'clsx';
import React from 'react';

import styles from './LogoutIcon.module.scss';

type IconProps = {
  className?: ClassValue;
  stroke?: ClassValue;
  fill?: ClassValue;
};

export const LogoutIcon: React.FC<IconProps> = ({ className, stroke, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="19.573" className={clsx(styles.Icon, className)}>
    <g fill="none">
      <path d="M9.016-.001L0 1.746v16.109l9.016 1.718z" className={clsx(styles.stroke, stroke)} />
      <path
        d="M7.716 1.576L1.3 2.818v13.961l6.416 1.223V1.576M9.016 0v19.573L0 17.855V1.745L9.016 0z"
        className={clsx(styles.fill, fill)}
      />
    </g>
    <g fill="none" strokeMiterlimit="10" strokeWidth="1.3" className={clsx(styles.stroke, stroke)}>
      <path d="M10.002 10.047h5.218M13.191 7.803l2.188 2.276-2.276 2.188" className={clsx(styles.stroke, stroke)} />
    </g>
    <path
      d="M6.561 10.003H4.782"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.3"
      className={clsx(styles.stroke, stroke)}
    />
    <path
      d="M9.016 3.463h2.674v5.046M9.016 17.386h2.674v-6.007"
      fill="none"
      strokeWidth="1.3"
      className={clsx(styles.stroke, stroke)}
    />
  </svg>
);
