import React, { useEffect } from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { LoginWizard } from '@components/auth/LoginWizard/LoginWizard';
import { LoginType, WizardState } from '@components/auth/LoginWizard/LoginWizard.interface';
import { useDefaultArea } from '@hooks/useDefaultArea';
import { useUser } from '@hooks/useUser';

type Program = 'VRPS' | 'lockdown' | 'ci';

type Invites = {
  [key in Program]: string;
};

const invites: Invites = {
  VRPS: 'df541d80-5481-4a59-82ed-0e6e182eeb50',
  lockdown: 'e52652d4-0b5a-4155-931b-ae0fd76c6c9e',
  ci: 'f7e3097b-d629-4c2f-83d3-7ebb5e93a729',
};

const isVRPS = (pathname: string) => pathname.indexOf('/host/score') >= 0;
const isLockdown = (pathname: string) => pathname.indexOf('/investor/historical') >= 0;
const isCI = (pathname: string) => pathname.indexOf('/properties') >= 0;

const getProgram = (pathname: string): Program | undefined => {
  if (isVRPS(pathname)) return 'VRPS';
  if (isLockdown(pathname)) return 'lockdown';
  if (isCI(pathname)) return 'ci';
  return undefined;
};

const applyToProgram = async (program?: Program) => {
  if (!program) return Promise.resolve(false);

  const invite = invites[program];
  await fetch('/api/apply', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ invite }),
  });

  return Promise.resolve(true);
};

type Props = {
  loginType?: LoginType;
  state?: WizardState;
  onStep?: (state: WizardState) => void;
};
// on email login this will send the apply
export const useAppyToProgram = () => {
  const {
    pathname,
    query: { ell },
  } = useRouter();

  useEffect(() => {
    // Apply to program after returning from an email-login redirect
    if (ell?.includes('ok') && pathname) {
      applyToProgram(getProgram(pathname));
    }
  }, [ell, pathname]);
};

const buildRedirectUrl = (asPath: string, query: any): string => {
  const utmPresent = query.utm_source || query.utm_term || query.utm_content;
  return utmPresent ? `${asPath}&signin=true` : asPath;
};

export const Login: React.FC<Props> = ({ state, onStep }) => {
  const { updateUser } = useUser();
  const { DEFAULT_AREA_ID, loading } = useDefaultArea();

  const {
    push,
    query: { rd },
    asPath,
    query,
  } = useRouter();

  const urlToRedirect = buildRedirectUrl(asPath, query);

  const currency = cookie.get('currency') || '';

  // investigate how to add apply to program, and how to get status
  // const onAuthSuccess = async (status: AuthStatus) => {
  //   // if (status.info?.created) {
  //   //   if (status.provider !== 'email-login-link') {
  //   //     applyToProgram(getProgram(pathname));
  //   //   }
  //   // }
  // };

  const onClickDescriptionSave = (userDescriptionType: string) => {
    if (userDescriptionType !== '') {
      updateUser({ variables: { input: { userDescriptionType, currency } } });
    } else {
      updateUser({ variables: { input: { currency } } });
    }
  };

  useEffect(() => {
    if (!loading && rd) {
      push('/investor/historical/[slug]', `/investor/historical/${DEFAULT_AREA_ID}`);
    }
  }, [DEFAULT_AREA_ID, loading, push, rd]);

  return (
    <LoginWizard
      state={state}
      onStep={onStep}
      onClickDescriptionSave={onClickDescriptionSave}
      redirectUrlClerk={urlToRedirect}
    />
  );
};
