import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { GetAreaInfo, AreaInfo, GET_AREA } from '@apolloCli/queries/areas';
import { DatesRange } from '@apolloCli/queries/datesRanges';

type Props = {
  areaId?: number;
  skip?: boolean;
};

export const useAreaInfo = ({ areaId: inAreaId, skip }: Props = {}) => {
  const router = useRouter();
  const { slug } = router.query;
  const [areaId, setAreaId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [areaInfo, setAreaInfo] = useState<AreaInfo>();
  const [isComplete, setIsComplete] = useState(false);
  const [datesRanges, setDatesRanges] = useState<DatesRange[]>();
  const {
    data: areaData,
    loading: areaDataLoading,
    called,
    error,
  } = useQuery<GetAreaInfo>(GET_AREA, {
    variables: { areaId, withDatesRanges: true },
    onCompleted: () => {
      setIsComplete(true);
    },
    skip: !areaId || skip,
  });

  useEffect(() => {
    let newAreaId;
    let slugCalled = false;
    if (inAreaId !== undefined) {
      newAreaId = inAreaId;
      slugCalled = true;
    } else if (slug) {
      const slugId = Number(slug);
      if (!Number.isNaN(slugId)) newAreaId = slugId;
      slugCalled = true;
    }

    if (areaId !== newAreaId) {
      setAreaId(newAreaId);
      setIsComplete(false);
    }

    let loadingStatus = true;
    if (slugCalled && newAreaId !== undefined && called) {
      loadingStatus = areaDataLoading;
    }

    if (slugCalled && newAreaId === undefined) {
      loadingStatus = false;
      setIsComplete(true);
    }

    if (!loadingStatus) {
      setAreaInfo((areaData?.areas || [])[0]);
      setDatesRanges(areaData?.datesRanges ? areaData.datesRanges : undefined);
    }

    setLoading(loadingStatus);
  }, [slug, inAreaId, areaDataLoading, areaData, called, areaId]);

  return {
    areaId,
    areaInfo,
    loading,
    isComplete,
    datesRanges,
    error,
  };
};
