import gql from 'graphql-tag';

export type ExchangeRatesInput = {
  base?: String;
  symbols: String[];
  startDate?: String;
  endDate?: String;
};

export const GET_EXCHANGES_RATES = gql`
  query exchangeRates($input: ExchangeRatesInput!) {
    exchangeRates(input: $input) {
      latest {
        symbol
        rate
      }
    }
  }
`;
