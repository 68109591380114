import gql from 'graphql-tag';

export type FollowArea = {
  clientId: number;
  area: {
    id: number;
    name: string;
    fullName?: string;
    city?: string;
    country?: string;
    areaType?: string;
  };
};

export type FollowAreas = {
  followAreas: FollowArea[];
};

export const GET_FOLLOW_AREAS = gql`
  query getFollowAreas {
    followAreas {
      clientId
      area {
        id
        name
        fullName
        city
        country
        areaType
      }
    }
  }
`;
