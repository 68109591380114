import gql from 'graphql-tag';

export const GET_UI = gql`
query getUi{
  percentiles @client
  dataAnalyzeSection @client
}
`;

export const GET_FILTERS = gql`
query getFilters{
  filters @client
}
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications{
    notifications @client
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation AddNotification( $description: String, $id: Int!, $title: String!, $type: String!) {
    createNotification(description: $description, id: $id, title: $title, type: $type) @client
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation RemoveNotification ($id: Int!) {
    deleteNotification(id: $id) @client
  }
`;

export const GET_DATA_ANALYZE_SECTION = gql`
{
  dataAnalyzeSection @client
}
`;

export const GET_SIDE_MENU = gql`
  query getSideMenu{
    isSideMenu @client
  }
`;

export const GET_CURRENCY_MODAL = gql`
  query getCurrencyModal{
    isCurrencyModal @client
  }
`;

export const GET_MARKET_MENU = gql`
  query getMarketMenu{
    isMarketMenu @client
  }
`;

export const GET_SUBSCRIBE_MODAL = gql`
  query getSubscribeModal{
    isSubscribeModal @client
  }
`;

export const GET_GLOBAL_NOTIFICATION = gql`
  query getGlobalNotification{
    globalNotification @client
  }
`;

export const GET_CURRENCY_CODE = gql`
  query getCurrencyCode{
    currencyCode @client
  }
`;

export const GET_METRICS = gql`
  query getMetrics{
    metrics @client
  }
`;
