import React, { useEffect } from 'react';

type refType = React.RefObject<HTMLElement> | undefined;

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  ref: refType | refType[] | string,
  action?: () => void,
  options: {
    disableTouchStart?: boolean;
  } = {
    disableTouchStart: true,
  },
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: UIEvent) {
      let refs;

      if (!ref || !action) return;

      if (typeof ref === 'string') {
        refs = Array.from(document.querySelectorAll(`[${ref}]`));
      } else if (Array.isArray(ref)) {
        refs = ref.filter((refi): refi is React.RefObject<HTMLElement> => !!refi).map((refi) => refi.current);
      } else {
        refs = [ref.current];
      }

      let doAction = true;
      refs.forEach((element: any) => {
        if (element && element.contains(event.target as Node)) doAction = false;
      });
      if (doAction) action();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    if (!options.disableTouchStart) {
      document.addEventListener('touchstart', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      if (!options.disableTouchStart) {
        document.removeEventListener('touchstart', handleClickOutside);
      }
    };
  }, [ref, action, options]);
};
