/* eslint-disable max-len */
import clsx, { ClassValue } from 'clsx';
import React from 'react';

type IconProps = {
  className?: ClassValue;
};

export const CreditCardIcon: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 96 960 960" width="14" className={clsx(className)}>
    <path d="M886.218 318.152v515.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H142.152q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865V318.152q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h675.696q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033Zm-744.066 106.37h675.696v-106.37H142.152v106.37Zm0 129v280.326h675.696V553.522H142.152Zm0 280.326V318.152v515.696Z" />
  </svg>
);
