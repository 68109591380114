import gql from 'graphql-tag';

export type DatesRange = {
  providerId: string;
  minDate: number;
  maxDate: number;
};

export type DatesRangesInfo = {
  datesRanges: DatesRange[];
};

// type: 'forward' is used on property page requests. However, if the type is empty, it assumes 'stats' by default.
export const GET_DATES_RANGES = gql`
  query getDatesRanges($type: DateRangesTypesOptions) {
    datesRanges(type: $type) {
      providerId
      minDate
      maxDate
    }
  }
`;
