/* eslint-disable react/button-has-type */
import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { LockedIcon } from '@components/iconography/LockedIcon/LockedIcon';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';

import styles from './Button.module.scss';

export type ButtonTheme =
  | 'default'
  | 'blueLine'
  | 'yellow'
  | 'lightBlue'
  | 'white'
  | 'transparent'
  | 'red'
  | 'yellowGradient'
  | 'transparentBorderWhite'
  | 'blue'
  | 'green'
  | 'mintGreen'
  | 'gray';

type Props = {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: ClassValue;
  isLoading?: boolean;
  type?: 'submit' | 'button' | 'link' | 'loading';
  href?: string;
  target?: '_blank';
  onClick?: (event: React.MouseEvent) => void;
  locked?: boolean;
  onClickLocked?: () => void;
  dataTestId?: string;
  size?: 'sm' | 'md' | 'lg';
  dataGuideTourId?: string;
  children: React.ReactNode;
};

const variants = {
  show: {
    width: 'auto',
  },
  hide: {
    width: 0,
  },
};

export const Button: React.FC<Props> = ({
  theme = 'default',
  disabled,
  className,
  isLoading,
  type,
  href,
  target,
  onClick,
  children,
  locked,
  onClickLocked,
  dataTestId,
  size,
  dataGuideTourId,
}) => {
  const buttonStyle = {
    [styles.DefaultTheme]: theme === 'default',
    [styles.BlueLineTheme]: theme === 'blueLine',
    [styles.WhiteTheme]: theme === 'white',
    [styles.YellowTheme]: theme === 'yellow',
    [styles.MintGreenTheme]: theme === 'mintGreen',
    [styles.GreenTheme]: theme === 'green',
    [styles.YellowGradientTheme]: theme === 'yellowGradient',
    [styles.LightBlueTheme]: theme === 'lightBlue',
    [styles.TransparentTheme]: theme === 'transparent',
    [styles.TransparentBorderWhiteTheme]: theme === 'transparentBorderWhite',
    [styles.RedTheme]: theme === 'red',
    [styles.BlueTheme]: theme === 'blue',
    [styles.GrayTheme]: theme === 'gray',
    [styles.Locked]: locked,
    [styles.Loading]: isLoading,
    [styles.Disabled]: disabled,
    [styles.ButtonSmall]: size === 'sm',
    [styles.ButtonMedium]: size === 'md',
    [styles.ButtonLarge]: size === 'lg',
  };

  const handlerClick = (event: React.MouseEvent) => {
    if (locked && onClickLocked) {
      onClickLocked();
    } else if (!locked && onClick) {
      onClick(event);
    }
  };

  if (type === 'submit' || type === 'button') {
    return (
      <button
        type={type}
        disabled={disabled}
        onClick={handlerClick}
        data-test-id={dataTestId}
        className={clsx(styles.Button, buttonStyle, className)}
        data-guide-tour-id={dataGuideTourId}
      >
        <LockedIcon className={styles.LockedIcon} />
        {children}
      </button>
    );
  }

  if (type === 'loading') {
    return (
      <button
        type="submit"
        disabled={disabled}
        onClick={handlerClick}
        data-test-id={dataTestId}
        className={clsx(styles.Button, buttonStyle, className)}
        data-guide-tour-id={dataGuideTourId}
      >
        <AnimatePresence>
          {isLoading && (
            <motion.div
              initial={variants.hide}
              animate={variants.show}
              exit={variants.hide}
              className={styles.ButtonContent}
            >
              <LogoDoors className={styles.Logo} loop />
            </motion.div>
          )}
          {!isLoading && (
            <motion.div
              initial={variants.hide}
              animate={variants.show}
              exit={variants.hide}
              className={styles.ButtonContent}
            >
              <LockedIcon className={styles.LockedIcon} />
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </button>
    );
  }

  return (
    <a
      href={href}
      target={target}
      onClick={handlerClick}
      data-test-id={dataTestId}
      className={clsx(styles.Button, buttonStyle, className)}
      data-guide-tour-id={dataGuideTourId}
    >
      <LockedIcon className={styles.LockedIcon} />
      {children}
    </a>
  );
};
