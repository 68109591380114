import { setTimeout } from 'timers';

import React, { useState, useRef, useEffect } from 'react';
import clsx, { ClassValue } from 'clsx';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { Box } from '@components/layout/Box/Box';
import { Portal } from '@components/utils/Portal/Portal';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';

import styles from './DropdownBox.module.scss';
import { BoundingBoxSize, getBoundingBox } from './DropdownBox.utils';

export type Position = 'default' | 'bottom' | 'top' | 'left' | 'right' | 'bottom-auto-align' | 'top-right';

type Props = {
  isOpen?: boolean;
  fixed?: boolean;
  dropDownBoxClassContainer?: ClassValue;
  padding?: 'none' | 'small' | 'big';
  position?: Position;
  target?: React.RefObject<HTMLElement>; // Dropdown target
  className?: ClassValue;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickOut?: () => void;
  children?: React.ReactNode;
  showArrow?: boolean;
  boxOffSetManual?: number;
  theme?: 'light' | 'dark';
  isPercentage?: boolean;
  arrowClassName?: ClassValue;
};

const AnimatedBox = motion(Box);

const getOffsetFix = (
  boxRef: React.RefObject<HTMLDivElement>,
  position: Props['position'],
  target?: React.RefObject<HTMLElement>,
) => {
  let offset = 0;
  if (boxRef.current) {
    const bounding = boxRef.current.getBoundingClientRect();
    const vpWidth = document.documentElement.clientWidth;

    // if (bounding.top >= 0)
    // if (bounding.bottom <= vpHeight)
    if (position === 'bottom-auto-align' && target && target.current) {
      const boundingTarget = target.current.getBoundingClientRect();
      const bodyWidth = document.body.clientWidth;

      return boundingTarget.left + bounding.width >= bodyWidth // if the component overflow in right side
        ? -bounding.width + boundingTarget.width
        : 1; //  if you return 0 it recalculates the offset in other side
    }
    const boxPadding = 12;
    if (bounding.left - boxPadding < 0) offset = -(bounding.left - boxPadding);
    if (bounding.right + boxPadding > vpWidth) offset = vpWidth - (bounding.right + boxPadding);
  }
  return Math.round(offset);
};

const variants = (position: Position): Variants => {
  switch (position) {
    case 'bottom-auto-align':
    case 'default':
      return {
        open: { height: 'auto' },
        close: { height: 0, transition: { ease: 'backIn' } },
      };
    case 'bottom':
      return {
        open: {
          opacity: 1,
          y: 0,
          pointerEvents: 'none',
          transitionEnd: { pointerEvents: 'auto' },
        },
        close: { opacity: 0, y: -20, transition: { duration: 0.2 } },
      };
    case 'top-right':
    case 'top':
      return {
        open: {
          opacity: 1,
          y: 0,
          pointerEvents: 'none',
          transitionEnd: { pointerEvents: 'auto' },
        },
        close: {
          pointerEvents: 'none',
          opacity: 0,
          y: 20,
          transition: { duration: 0.2 },
        },
      };
    case 'left':
      return {
        open: { opacity: 1, x: 0 },
        close: { opacity: 0, x: 20, transition: { duration: 0.2 } },
      };
    case 'right':
      return {
        open: { opacity: 1, x: 0 },
        close: { opacity: 0, x: -20, transition: { duration: 0.2 } },
      };
    default:
      return {
        open: { height: 'auto' },
        close: { height: 0, transition: { ease: 'backIn' } },
      };
  }
};

export const DropdownBox: React.FC<Props> = ({
  isOpen,
  className,
  padding,
  position = 'default',
  children,
  target,
  onMouseEnter,
  onMouseLeave,
  onClickOut,
  showArrow = true,
  fixed,
  dropDownBoxClassContainer,
  boxOffSetManual,
  theme = 'light',
  isPercentage,
  arrowClassName,
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [targetOffSet, setTargetOffset] = useState<BoundingBoxSize>();
  const [boxOffSet, setBoxOffset] = useState<number>();

  useOutsideAlerter([target, boxRef], onClickOut);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isOpen) return;
      if (target?.current && position !== 'default') {
        setTargetOffset(getBoundingBox(target.current));
      }

      if (['bottom', 'top', 'default', 'bottom-auto-align'].includes(position)) {
        setTimeout(() => {
          const of = getOffsetFix(boxRef, position, target);
          if (boxOffSet === undefined || of !== 0) {
            setBoxOffset(of);
          }
        }, 1);
      }
    }, 1);
    return () => clearInterval(intervalId);
  }, [isOpen, target, position, boxOffSet]);

  const BoxChild = (
    <AnimatedBox
      key="MenuContent"
      ref={boxRef}
      className={clsx(
        styles.DropdownBox,
        {
          [styles.default]: position === 'default' || position === 'bottom-auto-align',
          [styles.top]: position === 'top',
          [styles.topRight]: position === 'top-right',
          [styles.bottom]: position === 'bottom',
          [styles.left]: position === 'left',
          [styles.right]: position === 'right',
          [styles.bottomNoArrow]: position === 'bottom' && !showArrow,
          [styles.topNoArrow]: position === ('top' || 'top-right') && !showArrow,
          [styles.leftNoArrow]: position === 'left' && !showArrow,
          [styles.rightNoArrow]: position === 'right' && !showArrow,
          [styles.DarkTheme]: theme === 'dark',
        },
        isPercentage && styles.DropdownPercentage,
        className,
      )}
      padding={padding}
      initial="close"
      animate="open"
      exit="close"
      style={{ x: boxOffSet }}
      variants={variants(position)}
    >
      {showArrow && (
        <div
          className={clsx(styles.Arrow, arrowClassName)}
          style={{ marginLeft: `${-(boxOffSet || 0) - (boxOffSetManual || 11)}px` }}
        />
      )}
      {children}
    </AnimatedBox>
  );

  return (
    <AnimatePresence>
      {isOpen &&
        (target && position !== 'default' && position !== 'bottom-auto-align' && position !== 'top-right' ? (
          <Portal>
            <div
              className={clsx(
                styles.DropdownBoxContainer,
                dropDownBoxClassContainer,
                fixed && styles.DropdownBoxContainerFixed,
              )}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={targetOffSet}
            >
              {BoxChild}
            </div>
          </Portal>
        ) : (
          BoxChild
        ))}
    </AnimatePresence>
  );
};
