import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useWindowSize = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    const debouncedHandleResize = debounce(handleResize, 700);
    window.addEventListener('resize', debouncedHandleResize);
    debouncedHandleResize();
    return () => {
      debouncedHandleResize.cancel();
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return { width };
};
