/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isNewFollowAreaVar, isPropertyTrackedVar } from '@apolloCli/policies/uiPolicy';
import { performTourFunction, TourFunction, Tours } from '@components/tours/utils';
import { useSplitio } from '@hooks/useSplitioSwitch';
import { BENCHMARKING_FEATURE } from '@const/features';

interface GenerateRouterProps {
  label: string;
  defaultArea?: number;
}

export enum TabNamesEnum {
  INVESTOR = 'Investor',
  HOST = 'Host',
  ENTERPRISE = 'Enterprise',
  ACCOUNT = 'Account',
  SUBSCRIPTIONS = 'Subscriptions',
  BILLING = 'Billing',
  FOR_YOU = 'For You',
  OVERVIEW = 'Overview',
  HISTORICAL = 'Historical',
  FUTURE = 'Future',
  COMPETITOR_TRACKER = 'Competitor Tracker',
  EXPLORE = 'Explore',
  COMPARE = 'Compare',
  REVENUE_CALCULATOR = 'Revenue Calculator',
  PROPERTY_DETAILS = 'Property Details',
  TOP_PROPERTIES = 'Top Properties',
  SCORE = 'Score',
  COMPS = 'Comps',
  HOST_TOP_PROPERTIES = 'Top Properties',
  API_SUBSCRIPTIONS = 'Enterprise Subscriptions',
  ACCOUNT_ENTERPRISE = 'Enterprise Account',
  BILLING_ENTERPRISE = 'Enterprise Billing',
  XENIA = 'XENIA',
  REVENUE_CACULATOR_ENTERPRISE = 'Rev Calculator',
  BENCHMARKING = 'Benchmarking',
  MY_PROPERTIES = 'My Properties',
  MY_COMPS = 'My Comps',
}

export enum TabURLSEnum {
  INVESTOR_URL = '/investor',
  HOST_URL = '/host',
  ENTERPRISE_URL = '/enterpriseV2',
  ACCOUNT_PAGE_URL = '/account-settings',
  ACCOUNT_URL = '/account-settings?tab=account',
  SUBSCRIPTIONS_URL = '/account-settings?tab=subscriptions',
  BILLING_URL = '/account-settings?tab=billing',
  FOR_YOU_URL = '/investor/for-you',
  OVERVIEW_URL = '/investor/overview',
  HISTORICAL_URL = '/investor/historical',
  FUTURE_URL = '/investor/future',
  COMPETITOR_TRACKER_URL = '/investor/comps',
  EXPLORE_URL = '/investor/explore',
  COMPARE_URL = '/investor/compare',
  REVENUE_CALCULATOR_URL = '/investor/calculator',
  PROPERTY_DETAILS_URL = '/investor/property-details',
  TOP_PROPERTIES_URL = '/investor/top-properties',
  SCORE_URL = '/host/score',
  COMPS_URL = '/host/comps',
  HOST_TOP_PROPERTIES_URL = '/host/top-properties',
  HOME_URL = '/',
  ACCOUNT_URL_ENTERPRISE = '/enterpriseV2/account-settings?tab=account',
  SUBSCRIPTIONS_URL_ENTERPRISE = '/enterpriseV2/account-settings?tab=subscriptions',
  BILLING_URL_ENTERPRISE = '/enterpriseV2/account-settings?tab=billing',
  REVENUE_CALCULATOR_ENTERPRISE_URL = '/enterpriseV2/pm-portal?tab=revenue-calculator',
  XENIA_URL = '/enterpriseV2/pm-portal?tab=xenia',
  BENCHMARKING_URL = '/enterpriseV2/pm-portal?tab=benchmarking',
  MY_PROPERTIES_URL = '/enterpriseV2/pm-portal?tab=my-properties',
  MY_COMPS_URL = '/enterpriseV2/pm-portal?tab=my-comps',
}

export enum AccountTabEnum {
  ACCOUNT_TAB = 'account',
  SUBSCRIPTION_TAB = 'subscriptions',
  BILLING_TAB = 'billing',
}

export enum PMPortalTabEnum {
  REVENUE_CALCULATOR = 'revenue-calculator',
  XENIA = 'xenia',
  BENCHMARKING = 'benchmarking',
  MY_PROPERTIES = 'my-properties',
  MY_COMPS = 'my-comps',
}

export enum GuideTourIdInvestorPages {
  FOR_YOU_ID = 'for-you-tab',
  RESEARCH_ID = 'research-tab',
  HISTORICAL_ID = 'historical-tab',
  FUTURE_ID = 'future-tab',
  OVERVIEW_ID = 'overview-tab',
  COMPETITOR_ID = 'competitor-tracker-tab',
  EXPLORE_ID = 'explore-tab',
  COMPARE_ID = 'compare-tab',
  REVENUE_CALCULATOR_ID = 'revenue-calculator-tab',
  TOP_PROPERTIES_ID = 'top-properties-tab',
}

export type TabOptions = {
  label: TabNamesEnum;
  isActive: boolean;
  path: TabURLSEnum;
  dataGuideTourId?: string;
  desc?: string;
};

export const headerParameters = () => {
  const [marketId, setMarketId] = useState<string | null>();
  const router = useRouter();

  const { pathname, query, asPath } = router;
  const isForYouPage = pathname.includes(TabURLSEnum.FOR_YOU_URL);
  const isOverviewPage = pathname.includes(TabURLSEnum.OVERVIEW_URL);
  const isHistoricalPage = pathname.includes(TabURLSEnum.HISTORICAL_URL);
  const isFuturePage = pathname.includes(TabURLSEnum.FUTURE_URL);
  const isCompsInvestorPage = pathname.includes(TabURLSEnum.COMPETITOR_TRACKER_URL);
  const isExplorePage = pathname.includes(TabURLSEnum.EXPLORE_URL);
  const isComparePage = pathname.includes(TabURLSEnum.COMPARE_URL);
  const isCalculatorPage = pathname.includes(TabURLSEnum.REVENUE_CALCULATOR_URL);
  const isPropertyDetailsPage = pathname.includes(TabURLSEnum.PROPERTY_DETAILS_URL);
  const isTopPropertiesPage = pathname.includes(TabURLSEnum.TOP_PROPERTIES_URL);
  const isInvestor = pathname.includes(TabURLSEnum.INVESTOR_URL);
  const isScorePage = pathname.includes(TabURLSEnum.SCORE_URL);
  const isCompetitorHostPage = pathname.includes(TabURLSEnum.COMPS_URL);
  const isHostTopProperties = pathname.includes(TabURLSEnum.HOST_TOP_PROPERTIES_URL);
  const isHost = pathname.includes(TabURLSEnum.HOST_URL);
  const isEnterprise = pathname.includes(TabURLSEnum.ENTERPRISE_URL);
  const isHome = pathname === TabURLSEnum.HOME_URL;
  const isAccountPage = pathname.includes(TabURLSEnum.ACCOUNT_PAGE_URL);
  const isAccountTab = query.tab === AccountTabEnum.ACCOUNT_TAB;
  const isSubscriptionTab = query.tab === AccountTabEnum.SUBSCRIPTION_TAB;
  const isBillingTab = query.tab === AccountTabEnum.BILLING_TAB;
  const isRevenueCalculatorEnterpriseTab = query.tab === PMPortalTabEnum.REVENUE_CALCULATOR;
  const isXeniaEnterpriseTab = query.tab === PMPortalTabEnum.XENIA;
  const isBenchmarkingEnterpriseTab = query.tab === PMPortalTabEnum.BENCHMARKING;
  const isMyPropertiesEnterpriseTab = query.tab === PMPortalTabEnum.MY_PROPERTIES;
  const isMyCompsEnterpriseTab = query.tab === PMPortalTabEnum.MY_COMPS;

  const { isActive: isBenchmarkingActive, isReady } = useSplitio({
    featureId: BENCHMARKING_FEATURE,
  });
  const isBenchmarkingActiveAndReady = isBenchmarkingActive && isReady;

  const mainHeaderOptions: TabOptions[] = [
    {
      label: TabNamesEnum.INVESTOR,
      isActive: isInvestor,
      path: TabURLSEnum.HISTORICAL_URL,
    },
    {
      label: TabNamesEnum.HOST,
      isActive: isHost,
      path: TabURLSEnum.SCORE_URL,
    },
    {
      label: TabNamesEnum.ENTERPRISE,
      isActive: isEnterprise,
      path: TabURLSEnum.ENTERPRISE_URL,
    },
  ];

  const accountOptions: TabOptions[] = [
    {
      label: TabNamesEnum.ACCOUNT,
      isActive: isAccountTab,
      path: TabURLSEnum.ACCOUNT_URL,
    },
    {
      label: TabNamesEnum.SUBSCRIPTIONS,
      isActive: isSubscriptionTab,
      path: TabURLSEnum.SUBSCRIPTIONS_URL,
    },
    {
      label: TabNamesEnum.BILLING,
      isActive: isBillingTab,
      path: TabURLSEnum.BILLING_URL,
    },
  ];

  const accountOptionsEnterprise: TabOptions[] = [
    {
      label: TabNamesEnum.ACCOUNT_ENTERPRISE,
      isActive: isAccountTab,
      path: TabURLSEnum.ACCOUNT_URL_ENTERPRISE,
    },
    {
      label: TabNamesEnum.API_SUBSCRIPTIONS,
      isActive: isSubscriptionTab,
      path: TabURLSEnum.SUBSCRIPTIONS_URL_ENTERPRISE,
    },
    {
      label: TabNamesEnum.BILLING_ENTERPRISE,
      isActive: isBillingTab,
      path: TabURLSEnum.BILLING_URL_ENTERPRISE,
    },
  ];

  const benchmarkingTabOptions = isBenchmarkingActiveAndReady
    ? [
      {
        label: TabNamesEnum.BENCHMARKING,
        isActive: isBenchmarkingEnterpriseTab,
        path: TabURLSEnum.BENCHMARKING_URL,
      },
      {
        label: TabNamesEnum.MY_PROPERTIES,
        isActive: isMyPropertiesEnterpriseTab,
        path: TabURLSEnum.MY_PROPERTIES_URL,
      },
      {
        label: TabNamesEnum.MY_COMPS,
        isActive: isMyCompsEnterpriseTab,
        path: TabURLSEnum.MY_COMPS_URL,
      },
    ]
    : [];

  const PMPortalEnterpriseOptions: TabOptions[] = [
    {
      label: TabNamesEnum.REVENUE_CACULATOR_ENTERPRISE,
      isActive: isRevenueCalculatorEnterpriseTab,
      path: TabURLSEnum.ACCOUNT_URL_ENTERPRISE,
    },
    {
      label: TabNamesEnum.XENIA,
      isActive: isXeniaEnterpriseTab,
      path: TabURLSEnum.XENIA_URL,
    },
    ...benchmarkingTabOptions,
  ];

  const investorOptions: TabOptions[] = [
    {
      label: TabNamesEnum.FOR_YOU,
      isActive: isForYouPage,
      path: TabURLSEnum.FOR_YOU_URL,
      dataGuideTourId: GuideTourIdInvestorPages.FOR_YOU_ID,
      desc: 'In the for you tab you can find a collection of all your purchased areas, your saved properties, areas and searches that you are interested in!',
    },
    {
      label: TabNamesEnum.OVERVIEW,
      isActive: isOverviewPage,
      path: TabURLSEnum.OVERVIEW_URL,
      dataGuideTourId: GuideTourIdInvestorPages.OVERVIEW_ID,
      desc: 'In this research tab you can see a market overall performance of the area you are interested in.',
    },
    {
      label: TabNamesEnum.HISTORICAL,
      isActive: isHistoricalPage,
      path: TabURLSEnum.HISTORICAL_URL,
      dataGuideTourId: GuideTourIdInvestorPages.HISTORICAL_ID,
      desc: 'This tab shows how your selected market has behaved in the past weeks, months or years.',
    },
    {
      label: TabNamesEnum.FUTURE,
      isActive: isFuturePage,
      path: TabURLSEnum.FUTURE_URL,
      dataGuideTourId: GuideTourIdInvestorPages.FUTURE_ID,
      desc: 'This tab will provide you with the projected performance for your selected market up to 6 months ahead.',
    },
    {
      label: TabNamesEnum.COMPETITOR_TRACKER,
      isActive: isCompsInvestorPage,
      path: TabURLSEnum.COMPETITOR_TRACKER_URL,
      dataGuideTourId: GuideTourIdInvestorPages.COMPETITOR_ID,
      desc: 'In the competitor tracker tab you can find the key metrics for all your saved properties to analyze how well your competitors are doing!',
    },
    {
      label: TabNamesEnum.EXPLORE,
      isActive: isExplorePage,
      path: TabURLSEnum.EXPLORE_URL,
      dataGuideTourId: GuideTourIdInvestorPages.EXPLORE_ID,
      desc: 'Use the explore tab to quickly filter and identify markets of interest.',
    },
    {
      label: TabNamesEnum.COMPARE,
      isActive: isComparePage,
      path: TabURLSEnum.COMPARE_URL,
      dataGuideTourId: GuideTourIdInvestorPages.COMPARE_ID,
      desc: 'The compare tab is super useful when you need to compare the performance of an area or a set of competitors!',
    },
    {
      label: TabNamesEnum.REVENUE_CALCULATOR,
      isActive: isCalculatorPage,
      path: TabURLSEnum.REVENUE_CALCULATOR_URL,
      dataGuideTourId: GuideTourIdInvestorPages.REVENUE_CALCULATOR_ID,
      desc: 'The revenue calculator tab is made to give an approximation on how well a short term rental will do based on the amenities you may have and the historical performance of other short term rentals similar to yours.',
    },
    {
      label: TabNamesEnum.TOP_PROPERTIES,
      isActive: isTopPropertiesPage,
      path: TabURLSEnum.TOP_PROPERTIES_URL,
      dataGuideTourId: GuideTourIdInvestorPages.TOP_PROPERTIES_ID,
      desc: 'If you want to have a quick look to the top properties within an area of interest, then the top properties tab is for you!',
    },
  ];

  const hostOptions: TabOptions[] = [
    {
      label: TabNamesEnum.SCORE,
      isActive: isScorePage,
      path: TabURLSEnum.SCORE_URL,
    },
    {
      label: TabNamesEnum.COMPS,
      isActive: isCompetitorHostPage,
      path: TabURLSEnum.COMPS_URL,
    },
    {
      label: TabNamesEnum.HOST_TOP_PROPERTIES,
      isActive: isHostTopProperties,
      path: TabURLSEnum.HOST_TOP_PROPERTIES_URL,
    },
  ];

  const generateHeaderRouter = (props: GenerateRouterProps) => {
    const { label, defaultArea } = props;
    const lastVisitedArea = localStorage.getItem('lastVisitedArea');
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tourId = urlSearchParams.get('tourId');
    const isTrendsTour = tourId === Tours.TRENDS;
    const isTopPropertiesTour = tourId === Tours.TOP_PROPERTIES;
    const isExploreCompareTour = tourId === Tours.EXPLORE_COMPARE;
    const lastHostProperty = localStorage.getItem('lastHostProperty');

    switch (true) {
      case label === TabNamesEnum.INVESTOR:
        router.push(`${TabURLSEnum.HISTORICAL_URL}/${lastVisitedArea || defaultArea}`);
        break;
      case label === TabNamesEnum.HOST:
        router.push(TabURLSEnum.SCORE_URL);
        break;
      case label === TabNamesEnum.ENTERPRISE:
        router.push(TabURLSEnum.ENTERPRISE_URL);
        break;
      case label === TabNamesEnum.ACCOUNT:
        router.push(TabURLSEnum.ACCOUNT_URL);
        break;
      case label === TabNamesEnum.SUBSCRIPTIONS:
        router.push(TabURLSEnum.SUBSCRIPTIONS_URL);
        break;
      case label === TabNamesEnum.BILLING:
        router.push(TabURLSEnum.BILLING_URL);
        break;
      case label === TabNamesEnum.FOR_YOU:
        isNewFollowAreaVar(false);
        router.push(TabURLSEnum.FOR_YOU_URL);
        break;
      case label === TabNamesEnum.OVERVIEW:
        router.push(`${TabURLSEnum.OVERVIEW_URL}/${lastVisitedArea || defaultArea}`);
        break;
      case label === TabNamesEnum.HISTORICAL:
        if (isTrendsTour) {
          router.push(`${TabURLSEnum.HISTORICAL_URL}/${lastVisitedArea || defaultArea}?tourId=${tourId}`);
        } else if (!marketId) {
          router.push(`${TabURLSEnum.HISTORICAL_URL}/${lastVisitedArea || defaultArea}`);
        } else {
          router.push(`${TabURLSEnum.HISTORICAL_URL}/${lastVisitedArea || defaultArea}?marketId=${marketId}`);
        }
        break;
      case label === TabNamesEnum.FUTURE:
        if (isTrendsTour) {
          router.push(`${TabURLSEnum.FUTURE_URL}/${lastVisitedArea || defaultArea}?tourId=${tourId}`);
        } else if (!marketId) {
          router.push(`${TabURLSEnum.FUTURE_URL}/${lastVisitedArea || defaultArea}`);
        } else {
          router.push(`${TabURLSEnum.FUTURE_URL}/${lastVisitedArea || defaultArea}?marketId=${marketId}`);
        }
        break;
      case label === TabNamesEnum.COMPETITOR_TRACKER:
        isPropertyTrackedVar(false);
        if (isTopPropertiesTour) {
          performTourFunction(TourFunction.NEXT, true);
          router.push(`${TabURLSEnum.COMPETITOR_TRACKER_URL}/${lastVisitedArea || defaultArea}?tourId=${tourId}`);
        } else if (!marketId) {
          router.push(`${TabURLSEnum.COMPETITOR_TRACKER_URL}/${lastVisitedArea || defaultArea}`);
        } else {
          router.push(`${TabURLSEnum.COMPETITOR_TRACKER_URL}/${lastVisitedArea || defaultArea}?marketId=${marketId}`);
        }
        break;
      case label === TabNamesEnum.EXPLORE:
        router.push(TabURLSEnum.EXPLORE_URL);
        break;
      case label === TabNamesEnum.COMPARE:
        if (isExploreCompareTour) {
          performTourFunction(TourFunction.NEXT, true);
          router.push(`${TabURLSEnum.COMPARE_URL}?tourId=${tourId}`);
        } else {
          router.push(TabURLSEnum.COMPARE_URL);
        }
        break;
      case label === TabNamesEnum.REVENUE_CALCULATOR:
        router.push(TabURLSEnum.REVENUE_CALCULATOR_URL);
        break;
      case label === TabNamesEnum.PROPERTY_DETAILS:
        router.push(TabURLSEnum.PROPERTY_DETAILS_URL);
        break;
      case label === TabNamesEnum.TOP_PROPERTIES:
        if (!marketId) {
          router.push(`${TabURLSEnum.TOP_PROPERTIES_URL}/${lastVisitedArea || defaultArea}`);
        } else {
          router.push(`${TabURLSEnum.TOP_PROPERTIES_URL}/${lastVisitedArea || defaultArea}?marketId=${marketId}`);
        }
        break;
      case label === TabNamesEnum.COMPS:
        router.push(`${TabURLSEnum.COMPS_URL}${lastHostProperty || ''}`);
        break;
      case label === TabNamesEnum.SCORE:
        router.push(TabURLSEnum.SCORE_URL);
        break;
      case label === `${TabNamesEnum.HOST_TOP_PROPERTIES}-HOST`:
        router.push(`${TabURLSEnum.HOST_TOP_PROPERTIES_URL}${lastHostProperty || ''}`);
        break;
      case label === TabNamesEnum.ACCOUNT_ENTERPRISE:
        if (isEnterprise) {
          router.push(TabURLSEnum.ACCOUNT_URL_ENTERPRISE);
        }
        break;
      case label === TabNamesEnum.API_SUBSCRIPTIONS:
        router.push(TabURLSEnum.SUBSCRIPTIONS_URL_ENTERPRISE);
        break;
      case label === TabNamesEnum.BILLING_ENTERPRISE:
        router.push(TabURLSEnum.BILLING_URL_ENTERPRISE);
        break;
      case label === TabNamesEnum.XENIA:
        router.push(TabURLSEnum.XENIA_URL);
        break;
      case label === TabNamesEnum.REVENUE_CACULATOR_ENTERPRISE:
        router.push(TabURLSEnum.REVENUE_CALCULATOR_ENTERPRISE_URL);
        break;
      case label === TabNamesEnum.BENCHMARKING:
        router.push(TabURLSEnum.BENCHMARKING_URL);
        break;
      case label === TabNamesEnum.MY_PROPERTIES:
        router.push(TabURLSEnum.MY_PROPERTIES_URL);
        break;
      case label === TabNamesEnum.MY_COMPS:
        router.push(TabURLSEnum.MY_COMPS_URL);
        break;
      default:
        router.push(`${TabURLSEnum.HISTORICAL_URL}/${lastVisitedArea || defaultArea}`);
        break;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const marketIdFromQuery = urlParams.get('marketId');

    setMarketId(marketIdFromQuery);
  }, [asPath]);

  return {
    isForYouPage,
    isOverviewPage,
    isFuturePage,
    isHistoricalPage,
    isCompsInvestorPage,
    isExplorePage,
    isComparePage,
    isPropertyDetailsPage,
    isTopPropertiesPage,
    isScorePage,
    isHost,
    isInvestor,
    isEnterprise,
    isCompetitorHostPage,
    isBillingTab,
    isAccountTab,
    isSubscriptionTab,
    isAccountPage,
    isHome,
    mainHeaderOptions,
    investorOptions,
    hostOptions,
    accountOptions,
    accountOptionsEnterprise,
    PMPortalEnterpriseOptions,
    generateHeaderRouter,
  };
};
