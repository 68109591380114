import * as React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './CloseButton.module.scss';

type Props = {
  onClick?: (event: React.MouseEvent) => void;
  className?: ClassValue;
  dataTestId?: string;
};

export const CloseButton = ({ onClick, className, dataTestId }: Props) => (
  <a data-test-id={dataTestId} className={clsx(styles.CloseButton, className)} onClick={onClick}>
    <span className={styles.CloseIcon} />
  </a>
);
