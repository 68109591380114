const theme = {
  spacing: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '40px',
  },
};

type spacing = 's' | 'm' | 'l' | 'xl';

export interface Styles {
  p?: spacing;
  ph?: spacing;
  pv?: spacing;
  pb?: spacing;
  pt?: spacing;
  pl?: spacing;
  pr?: spacing;
  m?: spacing;
  mh?: spacing;
  mv?: spacing;
  mb?: spacing;
  mt?: spacing;
  ml?: spacing;
  mr?: spacing;
}

export const StyleTheme = (props: Styles) => ({
  paddingTop: props.p ? theme.spacing[props.p] : props.pt && theme.spacing[props.pt],
  paddingLeft: props.p ? theme.spacing[props.p] : props.pl && theme.spacing[props.pl],
  paddingRight: props.p ? theme.spacing[props.p] : props.pr && theme.spacing[props.pr],
  paddingBottom: props.p ? theme.spacing[props.p] : props.pb && theme.spacing[props.pb],

  marginBottom: props.m ? theme.spacing[props.m] : props.mb && theme.spacing[props.mb],
  marginTop: props.m ? theme.spacing[props.m] : props.mt && theme.spacing[props.mt],
  marginLeft: props.m ? theme.spacing[props.m] : props.ml && theme.spacing[props.ml],
  marginRight: props.m ? theme.spacing[props.m] : props.mr && theme.spacing[props.mr],
});
