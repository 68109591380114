import { guidedTourVar, toursModalOpenVar } from '@apolloCli/policies/uiPolicy';
import { Tours } from '@components/tours/utils';
import { DEFAULT_CITY_ID } from '@const/dashboard';
import router from 'next/router';

const ICONS_PATH = '/images/icons/tours';

const baseFn = (path: string, tourId: Tours) => {
  toursModalOpenVar(false);
  router.push(path, undefined, { shallow: false });
  guidedTourVar({ id: tourId });
};

export type TourItem = {
  desc: string;
  icon: string;
  key: string;
  action: Function;
};

export const ITEMS: TourItem[] = [
  {
    desc: 'Identify how my listings perform in comparison to my competitors',
    icon: `${ICONS_PATH}/tour_host.svg`,
    key: 'vr_host',
    action() {
      const path = `/host/score?tourId=${Tours.HOST}`;
      baseFn(path, Tours.HOST);
    },
  },
  {
    desc: 'Calculate how much my property can earn in the STR market',
    icon: `${ICONS_PATH}/tour_calculator.svg`,
    key: 'potential_vr_host',
    action() {
      const path = `/investor/calculator?tourId=${Tours.CALCULATOR}`;
      baseFn(path, Tours.CALCULATOR);
    },
  },
  {
    desc: 'Identify and compare where is best to invest',
    icon: `${ICONS_PATH}/tour_explore_compare.svg`,
    key: 'vr_investor',
    action() {
      const path = `/investor/explore?tourId=${Tours.EXPLORE_COMPARE}`;
      baseFn(path, Tours.EXPLORE_COMPARE);
    },
  },
  {
    desc: 'Identify opportunities to improve my listing',
    icon: `${ICONS_PATH}/tour_top_properties.svg`,
    key: 'property_manager',
    action() {
      const path = `/investor/top-properties/${DEFAULT_CITY_ID}?tourId=${Tours.TOP_PROPERTIES}`;
      baseFn(path, Tours.TOP_PROPERTIES);
    },
  },
  {
    desc: 'Identify and analyze market trends and seasonality',
    icon: `${ICONS_PATH}/tour_trends.svg`,
    key: 'real_estate',
    action() {
      const path = `/investor/overview/${DEFAULT_CITY_ID}?tourId=${Tours.TRENDS}`;
      baseFn(path, Tours.TRENDS);
    },
  },
  {
    desc: 'Explore sample market & see features rundown',
    icon: `${ICONS_PATH}/tour_sample_market.svg`,
    key: 'other',
    action() {
      const path = `/investor/historical/${DEFAULT_CITY_ID}?tourId=${Tours.SAMPLE_MARKET}`;
      baseFn(path, Tours.SAMPLE_MARKET);
    },
  },
];
