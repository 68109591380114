export type BoundingBoxSize = {
  top: number,
  left: number,
  width: number,
  height: number,
}

// Get the position of the element in the DOM
export const getBoundingBox = (elem: HTMLElement): BoundingBoxSize => {
  const box = elem.getBoundingClientRect();

  // crossBrowser version
  // eslint-disable-next-line prefer-destructuring
  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    width: Math.round(box.width),
    height: Math.round(box.height),
  };
};
