import React from 'react';
import clsx from 'clsx';
import { User } from '@interfaces/User';
import { useUser } from '@hooks/useUser';
import useBreakpoint from '@utils/useBreakpoint';
import { SignIn } from '@clerk/clerk-react';

import { StepProps, WizardState } from './LoginWizard.interface';
import { EmailLoginStatus } from './EmailLoginStatus-v1/EmailLoginStatus';
import { TourDescSelector } from './TourDescSelector/TourDescSelector';
import { DescriptionSelector } from './DescriptionSelector/DescriptionSelector';
import styles from './LoginWizard.module.scss';

export type DescriptionType = User['userDescriptionType'];

type TourDescSelectorProps = StepProps & {
  onClickDescriptionSave?: (selectedDescriptionType: DescriptionType) => void;
  next?: (step: WizardState) => void;
  isLoginStep?: boolean;
  skip?: () => void;
};

type Props = {
  state?: WizardState;
  onStep?: (step: WizardState) => void;
  onClickDescriptionSave?: TourDescSelectorProps['onClickDescriptionSave'];
  redirectUrlClerk?: string;
};

const noopAuthState = () => {};

export const LoginWizard: React.FC<Props> = ({
  state = { step: 'signin' },
  onStep = noopAuthState,
  onClickDescriptionSave,
  redirectUrlClerk,
}) => {
  const { step, emailLoginAuthStatus } = state;
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  const { isLogin, loading: userLoading } = useUser();

  return (
    <div className={clsx(styles.LoginWizardContainer, step === 'profiles' && styles.Profiles)}>
      {step === 'signin' && !isLogin && !userLoading && <SignIn redirectUrl={redirectUrlClerk} />}
      {step === 'emailstatus' && <EmailLoginStatus next={onStep} status={emailLoginAuthStatus} />}
      {step === 'profiles' && isDesktop && (
        <TourDescSelector next={onStep} onClickDescriptionSave={onClickDescriptionSave} isLoginStep />
      )}
      {step === 'profiles' && !isDesktop && (
        <DescriptionSelector next={onStep} onClickDescriptionSave={onClickDescriptionSave} />
      )}
    </div>
  );
};
