import { guidedTourVar } from '@apolloCli/policies/uiPolicy';
import { Tour, ShepherdButtonWithType } from 'react-shepherd';

export type GuidedTour = {
  id: Tours;
  goToNextStep?: boolean;
  hideCurrentStep?: boolean;
  resetQuery?: boolean;
  cancelTour?: boolean;
};

export enum Tours {
  HOST = 'HOST',
  CALCULATOR = 'CALCULATOR',
  EXPLORE_COMPARE = 'EXPLORE_COMPARE',
  TOP_PROPERTIES = 'TOP_PROPERTIES',
  TRENDS = 'TRENDS',
  SAMPLE_MARKET = 'SAMPLE_MARKET',
}

export enum TourFunction {
  NEXT = 'goToNextStep',
  HIDE = 'hideCurrentStep',
  CANCEL = 'cancelTour',
  RESET_QUERY = 'resetQuery',
}

export function performTourFunction(tourFunction: TourFunction, value: boolean) {
  const tourState = guidedTourVar();
  if (tourState) {
    guidedTourVar({ ...tourState, [tourFunction]: value });
  }
}

const onTourFinished = () => {
  performTourFunction(TourFunction.RESET_QUERY, true);
};

export const shepherdClass = (classes: string) => {
  const prefix = 'shepherd-custom';
  return classes
    .split(' ')
    .map((className) => `${prefix}-${className}`)
    .join(' ');
};

export const buttons: Record<string, ShepherdButtonWithType> = {
  continue: { text: 'Continue', type: 'next' },
  finish: {
    text: 'Finish',
    action() {
      onTourFinished();
    },
  },
  skip: {
    text: `<p class="${shepherdClass('button-skip-text')}">Skip Tutorial <img src="/images/icons/Arrow.svg" /></p>`,
    type: 'cancel',
    classes: shepherdClass('button-skip'),
  },
};

export const customContinueButton = (callback: Function): ShepherdButtonWithType => ({
  text: `<p class="${shepherdClass('button-skip-text')}">Continue</p>`,
  action() {
    callback();
    performTourFunction(TourFunction.NEXT, true);
  },
});

export const defaultTourOptions: Tour.TourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
    scrollTo: false,
    arrow: false,
    classes: shepherdClass('step highlight'),
    highlightClass: shepherdClass('highlight-rounded'),
    modalOverlayOpeningRadius: 4,
  },
  useModalOverlay: true,
};

export const defaultTourOptionsWithoutModal: Tour.TourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
    scrollTo: false,
    arrow: false,
    classes: shepherdClass('step highlight'),
    highlightClass: shepherdClass('highlight-no-overlay'),
  },
  useModalOverlay: false,
};

export const getProgressBar = ({ nSteps, nStep }: { nSteps: number; nStep: number }) => {
  let progressHTML = '';
  for (let i = 1; i <= nSteps; i += 1) {
    const stepClass = i < nStep ? 'past' : i === nStep ? 'current' : 'upcoming';
    progressHTML += `<div class="step ${stepClass}"></div>`;
  }
  return `<div class="${shepherdClass('progress-bar')}">${progressHTML}</div>`;
};

const CHECK_INTERVAL = 1000;

export const waitForElementPromise = (selector: string, fn: Function = () => {}) =>
  new Promise<void>((resolve) => {
    fn();
    const MAX_ATTEMPTS = 10;
    let attempt = 0;

    const intervalId = setInterval(() => {
      if (document.querySelector(selector)) {
        clearInterval(intervalId);
        resolve();
      } else if (attempt >= MAX_ATTEMPTS) {
        clearInterval(intervalId);
        performTourFunction(TourFunction.CANCEL, true);
        resolve();
      }

      attempt += 1;
    }, CHECK_INTERVAL);
  });
