import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import {
  GET_ACTIVE_ORDERS_V2,
  ActiveOrderV2,
  ActiveOrderV2Query,
  PlanType,
  PlanScope,
  PlanScopeEnum,
  PlanTypeEnum,
  globalAreaId,
} from '@apolloCli/queries/activeOrders';
import { VrpsHistoryType } from '@apolloCli/queries/listings';
import { useListing } from '@hooks/useListing';

import { useUser } from './useUser';
import { useAreaInfo } from './useAreaInfo';

type AreaActiveOrdersOptions = {
  skip?: boolean;
  areaId?: number;
};

const defaultOptions: AreaActiveOrdersOptions = {
  skip: false,
};

/**
 * Search in the vrpsHistory one area that is also in the active orders, in case none found, return areaId
 * @param activeOrdersV2
 * @param vrpsHistory
 * @param areaId
 * @returns areaId number
 */
function findSubscribedParentAreaId(
  activeOrdersV2: ActiveOrderV2 | undefined,
  vrpsHistory: VrpsHistoryType[] | undefined,
  areaId: number,
): number {
  if (activeOrdersV2 && vrpsHistory) {
    const activeAreaScore = vrpsHistory.find((vrpsItem) =>
      activeOrdersV2.areas.some((area) => area.areaId === vrpsItem.areaId),
    );
    if (activeAreaScore) return activeAreaScore.areaId;
  }
  return areaId;
}

export const useAreaActiveOrdersV2 = (options: AreaActiveOrdersOptions = defaultOptions) => {
  const { skip, areaId: areaIdProp } = options;
  const [isSubscribed, setSubscribe] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [currentPlanId, setCurrentPlanId] = useState<string>();
  const [planType, setPlanType] = useState<PlanType>(PlanTypeEnum.FREE);
  const [planScope, setPlanScope] = useState<PlanScope>(PlanScopeEnum.MARKET);
  const [activeAreas, setActiveAreas] = useState<ActiveOrderV2>();
  const { loading: userLoading, isLogin, user, isGodmode } = useUser();
  const router = useRouter();
  const { slug, aid, providerId, pid } = router.query;
  const areaIdSlug = parseInt(`${slug}`, 10);
  const areaIdAid = parseInt(`${aid}`, 10);
  const areaId = areaIdProp || areaIdSlug || areaIdAid;
  const isGlobalPlan = planScope === PlanScopeEnum.GLOBAL;
  const isGodmodeOrHasGlobalPlan = isGodmode || isGlobalPlan;
  const { areaInfo } = useAreaInfo({ areaId });

  const { loading: listingLoading, listing } = useListing({
    areaId: Number(aid),
    uid: String(pid),
    providerId: String(providerId),
  });

  const {
    data,
    loading: ordersLoading,
    error,
    refetch,
  } = useQuery<ActiveOrderV2Query>(GET_ACTIVE_ORDERS_V2, {
    skip: skip || !isLogin || userLoading,
  });

  const loading = userLoading || ordersLoading || listingLoading;

  const refetchActiveOrdersV2 = () => refetch({ hack: Math.random() });

  useEffect(() => {
    if (data?.activeOrdersV2) {
      if (areaId && !loading && data?.activeOrdersV2.areas.length) {
        const subscribedParentAreaId = findSubscribedParentAreaId(data?.activeOrdersV2, listing?.vrpsHistory, areaId);
        const currentActive = data?.activeOrdersV2.areas.find(
          (activeOrder) =>
            activeOrder.areaId === subscribedParentAreaId ||
            activeOrder.containedAreas?.some((containedArea) => containedArea.areaId === areaId) ||
            activeOrder.areaId === areaInfo?.countryId ||
            activeOrder.areaId === areaInfo?.stateId ||
            activeOrder.areaId === globalAreaId,
        );
        const currentPlanType = currentActive?.planType || PlanTypeEnum.FREE;
        const currentPlanScope = currentActive?.planScope || PlanScopeEnum.MARKET;
        setSubscribe(isGodmodeOrHasGlobalPlan || !!currentActive);
        setPlanType(currentPlanType);
        setPlanScope(currentPlanScope);
        setCurrentPlanId(currentActive?.planId);
      } else {
        setSubscribe(false);
        setPlanType(PlanTypeEnum.FREE);
      }
      setActiveAreas(data.activeOrdersV2);
      if (isGodmodeOrHasGlobalPlan) {
        setSubscribe(isGodmodeOrHasGlobalPlan);
        setPlanType(PlanTypeEnum.PRO);
      }
      setIsReady(true);
    }
  }, [loading, data, areaId, user, listing, isGodmodeOrHasGlobalPlan, areaInfo]);

  const DEFAULT_ACTIVE_AREA_V2 =
    data?.activeOrdersV2 && data?.activeOrdersV2.areas.length > 0
      ? data?.activeOrdersV2.areas[0].defaultAreaId
      : undefined;

  return {
    loading,
    error,
    isSubscribed,
    planType,
    planScope,
    activeOrdersV2: activeAreas,
    currentPlanId,
    refetchActiveOrdersV2,
    DEFAULT_ACTIVE_AREA_V2,
    isReady,
    isGodmodeOrHasGlobalPlan,
  };
};
