import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import cookie from 'js-cookie';
import { GET_CURRENCY_CODE } from '@apolloCli/queries/ui';
import { currencyCodeVar } from '@apolloCli/policies/uiPolicy';
import { CurrencyCode } from '@interfaces/UI';
import { GET_EXCHANGES_RATES } from '@apolloCli/queries/exchangeRates';
import { CURRENCIES } from '@const/currencies';

import { useUser } from './useUser';

export type CurrencyCodes = keyof typeof CURRENCIES;
const currencyCodes = Object.keys(CURRENCIES);
const baseCurrency = 'USD';
const cookieName = 'currency';

export type HistoricalCurrencyData = {
  rates: Record<string, Record<CurrencyCodes, number>>;
};

export type Rate = {
  name: string;
  code: CurrencyCodes;
  symbol: string;
  value: number;
};

export type Rates = {
  [code in CurrencyCodes]?: Rate;
};

type Latest = {
  symbol: string;
  rate: number;
};

type Timeseries = {
  symbol: string;
  rates: Rates[];
};

type ExchangeRatesResult = {
  exchangeRates: {
    latest: Latest[];
    timeseries: Timeseries[];
  };
};

const initialRates: { USD: Rate } = {
  USD: {
    name: 'US dollar',
    code: 'USD',
    symbol: '$',
    value: 1,
  },
};

const parseRates = (rates: Latest[]) => {
  const parsedRates: Record<string, Rate> = {};
  rates.forEach((rateObj) => {
    const { symbol, rate } = rateObj;
    const currency = CURRENCIES[symbol as CurrencyCodes];

    if (currency) {
      parsedRates[symbol] = {
        name: currency.name,
        code: currency.code as CurrencyCodes,
        symbol: currency.symbol,
        value: rate,
      };
    }
  });
  return parsedRates;
};

export const useCurrency = () => {
  const [rates, setRates] = useState<Rates>(initialRates);
  const { data: dataCurrencyCode } = useQuery<CurrencyCode>(GET_CURRENCY_CODE);
  const currencyCode = dataCurrencyCode?.currencyCode || currencyCodeVar();
  const { user, updateUser } = useUser();
  const { data, loading, error } = useQuery<ExchangeRatesResult>(GET_EXCHANGES_RATES, {
    variables: {
      input: {
        base: baseCurrency,
        symbols: currencyCodes,
      },
    },
  });

  useEffect(() => {
    const selectedCurrencyCode = (cookie.get(cookieName) || baseCurrency) as CurrencyCodes;
    currencyCodeVar(selectedCurrencyCode);
  }, []);

  useEffect(() => {
    if (data?.exchangeRates?.latest) {
      const parseValues = parseRates(data?.exchangeRates?.latest);
      setRates(parseValues);
    }
  }, [data]);

  useEffect(() => {
    if (user?.currency === '') {
      updateUser({ variables: { input: { currency: currencyCode } } });
    }
  }, [user, currencyCode, updateUser]);

  const setCurrency = useCallback(
    (code: CurrencyCodes) => {
      const newCurrency = rates[code] || initialRates.USD;
      cookie.set(cookieName, newCurrency.code, { expires: 365 });
      updateUser({ variables: { input: { currency: newCurrency.code } } });
      currencyCodeVar(newCurrency.code);
    },
    [rates, updateUser],
  );

  const currency = rates[currencyCode] || initialRates.USD;

  const currencyConverter = (value?: number, noSymbol?: boolean) => {
    const currencyRate = currency.value;
    return value || value === 0 ? `${noSymbol ? '' : currency.symbol}${Math.round(currencyRate * value)}` : '';
  };

  return {
    rates,
    currency,
    setCurrency,
    currencyConverter,
    isLoading: loading,
    isError: error,
    historicalRates: {},
  };
};
