import React, { useState } from 'react';
import { useUser } from '@hooks/useUser';
import { ListMenu } from '@components/custom/ListMenu/ListMenu';
import { Position } from '@components/navigation/DropdownBox/DropdownBox';
import { marketingURLS, CONTACT_US_WEBAPP } from '@utils/links';

import styles from './HelpMenu.module.scss';

const helpMenuList = [
  {
    title: 'FAQs',
    icon: '/images/icons/helpMenu/FAQ.svg',
    href: marketingURLS.FAQ,
  },
  {
    title: 'Guides',
    icon: '/images/icons/info.svg',
    href: marketingURLS.KNOWLEDGE_BASE,
  },
  {
    title: 'Pricing Info',
    icon: '/images/icons/helpMenu/pricing.svg',
    href: marketingURLS.PRICING,
  },
  {
    title: 'Metrics Glosary',
    icon: '/images/icons/helpMenu/metrics.svg',
    href: marketingURLS.METRICS_GLOSSARY,
  },
  {
    title: 'Terms & Conditions',
    icon: '/images/icons/helpMenu/terms-&-conditions.svg',
    href: marketingURLS.TERMS_AND_CONDITIONS,
  },
  {
    title: 'Contact Us',
    icon: '/images/icons/helpMenu/contact-us.svg',
    href: CONTACT_US_WEBAPP,
  },
];

type Props = {
  className?: string;
  position?: Position;
  sideMenu?: boolean;
};

export const HelpMenu: React.FC<Props> = ({ sideMenu, className, position = 'bottom-auto-align' }) => {
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);
  const onClickOut = () => setShowOptions(false);
  const { isLogin } = useUser();

  const onClickListMenu = (href: string) => {
    if (href !== 'open_guided_tour') {
      setShowOptions(false);
      window.open(href, '_blank');
    }
  };

  return (
    <div>
      {isLogin && (
        <div className={className}>
          <ListMenu
            isHelpMenu
            list={helpMenuList}
            className={[styles.HelpMenuButton, sideMenu && styles.SideMenu]}
            containerListClass={styles.ContainerList}
            label="Help"
            itemTitle={Object.keys(helpMenuList[0])[0]}
            itemIcon={Object.keys(helpMenuList[0])[1]}
            itemAction={Object.keys(helpMenuList[0])[2]}
            showOptions={showOptions}
            toggleShowOptions={toggleShowOptions}
            onClickOut={onClickOut}
            onClickItem={onClickListMenu}
            showArrow={false}
            position={position}
            uppercase={false}
            iconType="url"
          />
        </div>
      )}
    </div>
  );
};
