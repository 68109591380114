/* eslint-disable max-len */
import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './Chevron.module.scss';

type Props = {
  active?: boolean;
  size?: 'small' | 'big' | 'micro' | 'xl';
  className?: ClassValue;
  theme?: 'triangle';
  fill?: string;
};

export const Chevron: React.FC<Props> = ({ active, size = 'small', className, theme, fill = '#192133' }) => {
  const mapClassnames = {
    xl: styles.ExtraLarge,
    big: styles.Bigger,
    small: styles.Smaller,
    micro: styles.Micro,
  };

  const stylesObject = {
    [styles.Chevron]: true,
    [styles.Chevron_Active]: active,
    [mapClassnames[size]]: true,
  };

  if (theme === 'triangle') {
    return <div className={clsx(styles.Triangle, stylesObject, className)} />;
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 8.5" className={clsx(stylesObject, className)}>
      <path
        d="M6.75 6.6L12.181.262a.75.75 0 011.058-.081.75.75 0 01.081 1.058l-6 7a.75.75 0 01-1.139 0l-6-7A.75.75 0 01.262.181.75.75 0 011.32.262z"
        fill={fill}
      />
    </svg>
  );
};
