import React from 'react';
import { Chevron } from '@components/iconography/Chevron/Chevron';
import { LockedIcon } from '@components/iconography/LockedIcon/LockedIcon';
import clsx, { ClassValue } from 'clsx';
import { PopOver } from '@components/communication/PopOver/PopOver';
import { ButtonTheme } from '@components/general/Button/Button';

import styles from './DropdownButton.module.scss';

type Props = {
  locked?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  hasChanges?: boolean;
  label: string | React.ReactNode;
  onClick: () => void;
  hideChevron?: boolean;
  theme?:
    | 'gray'
    | 'inverted'
    | 'collapse-mobile'
    | 'lightGray'
    | 'smallGray'
    | 'lightTextGray'
    | 'customGraySize'
    | 'custom'
    | 'white'
    | 'HelpMenuLightGrayTheme'
    | 'blue'
    | 'whiteBlue';
  className?: ClassValue;
  popOverClass?: ClassValue;
  popOverDisable?: boolean;
  popOverPosition?: 'bottom' | 'top' | 'left' | 'right';
  popOverDescription?: string | React.ReactNode;
  customLabel?: string | React.ReactNode;
  popOverBoxClassName?: ClassValue;
  chevronClass?: ClassValue;
  showPopover?: boolean;
  popOverShowCallToActionButton?: boolean;
  popOverCallToActionTheme?: ButtonTheme;
  popOverCallToActionClass?: ClassValue;
  popOverCallToActionText?: string;
  popOverOnClickCallToAction?: () => void;
  chevronText?: string;
  dataTestIdCurrency?: string;
  dataTestId?: string;
  dataGuideTourId?: string;
};

export const DropdownButton = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      locked,
      isActive,
      isSelected = false,
      hasChanges,
      label,
      onClick,
      hideChevron,
      chevronText,
      theme,
      className,
      popOverClass,
      popOverDisable = true,
      popOverPosition,
      popOverDescription,
      popOverBoxClassName,
      chevronClass,
      showPopover,
      popOverShowCallToActionButton,
      popOverCallToActionTheme,
      popOverCallToActionClass,
      popOverCallToActionText,
      popOverOnClickCallToAction,
      customLabel,
      dataTestId,
      dataTestIdCurrency,
      dataGuideTourId,
    },
    ref,
  ) => (
    <PopOver
      disable={popOverDisable}
      position={popOverPosition}
      description={popOverDescription}
      className={popOverClass}
      boxClassName={popOverBoxClassName}
      showPopover={showPopover}
      showCallToActionButton={popOverShowCallToActionButton}
      callToActionTheme={popOverCallToActionTheme}
      callToActionClass={popOverCallToActionClass}
      callToActionText={popOverCallToActionText}
      onClickCallToAction={popOverOnClickCallToAction}
    >
      <a
        ref={ref}
        className={clsx(
          styles.Container,
          hasChanges && styles.HasChanges,
          locked && styles.Locked,
          isActive && theme !== 'white' && styles.Active,
          theme === 'lightGray' && styles.LightGrayTheme,
          theme === 'gray' && styles.GrayTheme,
          theme === 'inverted' && styles.Inverted,
          theme === 'collapse-mobile' && styles.CollapseMobile,
          theme === 'smallGray' && styles.SmallGrayTheme,
          theme === 'lightTextGray' && styles.LightGrayTextTheme,
          theme === 'customGraySize' && styles.CustomGraySize,
          theme === 'custom' && styles.CustomTheme,
          theme === 'white' && styles.WhiteTheme,
          theme === 'HelpMenuLightGrayTheme' && styles.HelpMenuLightGrayTheme,
          theme === 'blue' && styles.BlueTheme,
          theme === 'whiteBlue' && styles.WhiteBlueTheme,
          customLabel && styles.CustomLabel,
          className,
        )}
        onClick={onClick}
        data-test-id={dataTestId}
        data-guide-tour-id={dataGuideTourId}
      >
        <div className={styles.IconContainer}>
          <svg viewBox="0 0 19.701 19" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M10.613 18.51H8.464v-8.6L1.031.51h17.643l-7.349 9.4v8.6z" fill="none" />
            </g>
          </svg>
        </div>
        <div className={clsx(styles.Content, styles.ChrevronPlusText)}>
          {locked && <LockedIcon className={styles.LockedIcon} />}
          {customLabel ? (
            <div className={clsx(styles.LabelCustom, isSelected && styles.SelectedCustom)}>{customLabel}</div>
          ) : (
            <div data-test-id={dataTestIdCurrency} className={clsx(styles.Label, isSelected && styles.Selected)}>
              {label}
            </div>
          )}

          {!chevronText ? (
            <div className={clsx(styles.ChevronContainer, hideChevron && styles.hide)}>
              <Chevron
                size={theme === 'collapse-mobile' ? 'micro' : undefined}
                active={isActive}
                className={chevronClass}
              />
            </div>
          ) : (
            <div className={hideChevron ? styles.hide : ''}>
              <div className={styles.ChevronAndTextContainer}>
                <span className={styles.ChevronText}>{chevronText}</span>
                <Chevron
                  size={theme === 'collapse-mobile' ? 'micro' : undefined}
                  active={isActive}
                  className={chevronClass}
                />
              </div>
            </div>
          )}
        </div>
      </a>
    </PopOver>
  ),
);
