import { PlanType } from '@apolloCli/queries/activeOrders';
import { FutureMetricsDataObject } from '@apolloCli/queries/futureMetricsData';
import { OptionDropdownFilter } from '@components/navigation/DropdownFilter/DropdownFilter.logic';
import { StatisticsObject } from '@interfaces/Metrics';
import dayjs from 'dayjs';
import { URL_PLAN_BASIC_IMAGE, URL_PLAN_PRO_IMAGE } from 'src/const/accountPlans';

type Options = {
  format?: string;
  min?: number;
  max?: number;
};

export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';

export const LAST_4_WEEKS = 'last_4_weeks';
export const LAST_3_MONTHS = 'last_3_months';
export const LAST_12_MONTHS = 'last_12_months';
// export const MONTH_TO_DATE = 'month_to_date';
// export const QUARTER_TO_DATE = 'quarter_to_date';
// export const YEAR_TO_DATE = 'year_to_date';
export const LAST_4_YEARS = 'last_4_years';

export const DEFAULT_DATARANGE = LAST_12_MONTHS;
export const DEFAULT_DATARANGE_WEEKLY = LAST_3_MONTHS;

export const MONTH_YEAR_FORMAT = 'MMMM YYYY';

export const MONTH_DAY_YEAR_FORMAT = 'MMM D, YYYY';

export type RangeTypes =
  | 'last_4_weeks'
  | 'last_3_months'
  | 'last_12_months'
  // | 'month_to_date'
  // | 'quarter_to_date'
  // | 'year_to_date'
  | 'last_4_years';

/**
 * Calculate date ranges from 'today' to 'numberOfMonths' backwards.
 *
 * @param {number | string} today Reference start day, type number is interpreted as unix time
 * and string is interpreted as iso 8601
 * @param {number} numberOfMonths Number of months backwards to calculate  date
 * @param {Object} options
 * @param {string} options.format Output format date ranges, default 'YYYY-MM-DD'
 * @param {number} options.min min date range cap in unix time format
 * @param {number} options.max max date range cap in unix time format
 * @returns {start, end} date ranges:
 * start: month's first day 'numberOfMonths' before today
 * end: month's last day before today
 */
export const getLastMonthsRange = (
  today: number | string,
  numberOfMonths: number,
  { format: defaultFormat, min, max }: Options = { format: YEAR_MONTH_DAY_FORMAT },
) => {
  const todayDate = typeof today === 'number' ? dayjs.unix(today) : dayjs(today);
  const n = numberOfMonths || 1;
  let nMonthFix = false;

  // if todayDate is the last day of the month, substract one month to the range
  if (
    dayjs(todayDate).format(YEAR_MONTH_DAY_FORMAT) === dayjs(todayDate).endOf('month').format(YEAR_MONTH_DAY_FORMAT)
  ) {
    nMonthFix = true;
  }

  const endDate = todayDate.subtract(nMonthFix ? 0 : 1, 'month').endOf('month');

  const startDate = todayDate.subtract(n - (nMonthFix ? 1 : 0), 'month').startOf('month');

  const start = min && min > startDate.unix() ? dayjs.unix(min).format(defaultFormat) : startDate.format(defaultFormat);

  const end = max && max < endDate.unix() ? dayjs.unix(max).format(defaultFormat) : endDate.format(defaultFormat);

  return { start, end };
};

export const getMonthsBackArray = (
  startMonth: string,
  monthsForward: number,
  canBeLocked: boolean,
  planType?: PlanType,
  isUserFlowV2?: boolean,
) => {
  let months: OptionDropdownFilter[] = [];
  for (let i = 1; i <= monthsForward; i += 1) {
    const newMonth = dayjs(startMonth).add(monthsForward - i, 'month');

    const paymentMonthsImage = months.length === 4 ? URL_PLAN_BASIC_IMAGE : URL_PLAN_PRO_IMAGE;
    const plansName = months.length === 4 ? 'BASIC' : 'PRO';
    months.unshift({
      name: newMonth.format(MONTH_YEAR_FORMAT),
      label: newMonth.format(MONTH_YEAR_FORMAT),
      value: newMonth.format(YEAR_MONTH_DAY_FORMAT),
      canBeLocked: !isUserFlowV2
        ? !(canBeLocked && newMonth.month() === dayjs(startMonth).month())
        : !(newMonth.month() === dayjs(startMonth).month()),
      planType: months.length === 5 ? 'FREE' : plansName,
      urlTagImage: months.length === 5 ? null : paymentMonthsImage,
    });
  }
  if (isUserFlowV2) {
    months.reduce<OptionDropdownFilter[]>((acc, item) => {
      if (planType === 'PRO') {
        const obj = { ...item, canBeLocked: false };
        acc.push(obj);
        months = acc;
        return months;
      }

      if (planType === 'BASIC') {
        const obj = { ...item, canBeLocked: item.planType === 'PRO' };
        acc.push(obj);
        months = acc;
        return months;
      }

      return months;
    }, []);
  }

  return months;
};

export const addHoursToTimestamp = (utcUnixTimestamp: number, offsetHours: number) =>
  utcUnixTimestamp + offsetHours * 3600 * -1;

export const formatDateShortString = (date: string) => dayjs(date).format('MMM D, YYYY');

export const formatDateLongString = (date: string) => dayjs(date).format('MMMM D, YYYY');

export const addOneMonth = (date: string) => dayjs(date).add(1, 'month').format(MONTH_YEAR_FORMAT);

export const formatDateString = (date: string, format: string) => dayjs(date).format(format);

export const currentMonthAndFormat = () => dayjs().add(1, 'day').startOf('month').format(YEAR_MONTH_DAY_FORMAT);

export const userMonthSelection = (month: string, data: FutureMetricsDataObject[]) =>
  Array.from(new Set(data.map((a) => a))).filter((a) => dayjs(a.forwardDate).month() === dayjs(month).month());

export const userMonthSelectionFutureAvailability = (month: string, data: FutureMetricsDataObject[]) =>
  Array.from(new Set(data.map((a) => a))).filter((a) => dayjs(a.forwardDate).month() === dayjs(month).month());

export const userMonthSelectionStatsFormat = (month: string, data: StatisticsObject[]) =>
  Array.from(new Set(data.map((a) => a))).filter((a) => dayjs(a.calendarDate).month() === dayjs(month).month());

const DATES_RANGES_OBJ = {
  '1m': {
    label: 'Last month',
  },
  '3m': {
    label: 'Last 3 months',
  },
  '12m': {
    label: 'Last 12 months',
  },
  '4y': {
    label: 'Last 4 years',
  },
};

type Args = {
  defaultDateRangeOption: string;
  customDateRange: {
    since: string;
    until: string;
  };
  format?: 'short' | 'long';
};

export const getDateRangeLabel = ({ defaultDateRangeOption, customDateRange, format = 'short' }: Args): string => {
  const defaultOption = DATES_RANGES_OBJ[defaultDateRangeOption as keyof typeof DATES_RANGES_OBJ];

  if (defaultOption) {
    return defaultOption.label;
  }

  const formatter = format === 'short' ? formatDateShortString : formatDateLongString;

  const since = formatter(customDateRange.since);
  const until = formatter(customDateRange.until);
  const label = `${since} - ${until}`;

  return label;
};
