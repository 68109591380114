import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx, { ClassValue } from 'clsx';
import { LockedIcon } from '@components/iconography/LockedIcon/LockedIcon';
import { PopOver } from '@components/communication/PopOver/PopOver';

import styles from './Radio.module.scss';

type OptionsProps = {
  size?: 'normal' | 'small';
  color?:
    | 'red'
    | 'blue'
    | 'yellow'
    | 'mint'
    | 'lavanda'
    | 'orange'
    | 'pink'
    | 'platano'
    | 'pyellow'
    | 'pred'
    | 'pblue'
    | 'pyellow2';
};

type Props = {
  label: string | React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  textClassName?: ClassValue;
  containerClassName?: ClassValue;
  theme?: 'bold-label' | 'blue-label' | 'default';
  locked?: boolean;
  onClickLocked?: () => void;
  options?: OptionsProps;
  showLockIcon?: boolean;
  setShowOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  radioClass?: ClassValue;
  dataTestId?: string;
  showPopOver?: boolean;
  descriptionPopOver?: string | React.ReactNode;
  widthPopOver?: number;
};

export const Radio: React.FC<Props> = ({
  label,
  checked,
  onChange,
  theme,
  textClassName,
  containerClassName,
  disabled,
  locked,
  onClickLocked,
  options,
  showLockIcon = false,
  setShowOptions,
  radioClass,
  dataTestId,
  showPopOver = false,
  descriptionPopOver,
  widthPopOver = 195,
}) => {
  const onClickContainer = () => {
    if (locked && onClickLocked) {
      onClickLocked();
    } else if (!disabled && onChange) onChange();
    if (setShowOptions) setShowOptions(false);
  };

  return (
    <div
      className={clsx(
        styles.Container,
        containerClassName,
        theme === 'bold-label' && styles.BoldLabelTheme,
        theme === 'default' && styles.DefaultTheme,
        disabled && styles.Disabled,
      )}
      onClick={onClickContainer}
    >
      <div
        className={clsx(
          styles.Circle,
          options?.size === 'small' && !locked && styles.CircleSmall,
          'radio-button',
          checked && [styles.active, 'radio-button-checked'],
          disabled && styles.CircleDisabled,
          options?.color === 'blue' && styles.ThemeBlue,
          options?.color === 'pblue' && styles.ThemeBlue,
          options?.color === 'red' && styles.ThemeRed,
          options?.color === 'pred' && styles.ThemeRed,
          options?.color === 'yellow' && styles.ThemeYellow,
          options?.color === 'pyellow' && styles.ThemeYellow,
          options?.color === 'mint' && styles.ThemeMint,
          options?.color === 'lavanda' && styles.ThemeLavanda,
          options?.color === 'orange' && styles.ThemeOrange,
          options?.color === 'pink' && styles.ThemePink,
          options?.color === 'platano' && styles.ThemePlatano,
          radioClass,
        )}
      >
        {locked && showLockIcon && <LockedIcon />}

        {(!locked || !showLockIcon) && (
          <AnimatePresence>
            {checked && (
              <motion.div
                className={clsx(styles.Point, options?.size === 'small' && styles.PointSmall)}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              />
            )}
          </AnimatePresence>
        )}
      </div>
      {showPopOver && disabled ? (
        <PopOver
          disableHoverEvents={!disabled}
          description={descriptionPopOver}
          position="bottom"
          theme="dark"
          width={widthPopOver}
        >
          <span data-test-id={dataTestId} className={clsx(styles.Text, textClassName)}>
            {label}
          </span>
        </PopOver>
      ) : (
        <span data-test-id={dataTestId} className={clsx(styles.Text, textClassName)}>
          {label}
        </span>
      )}
    </div>
  );
};
