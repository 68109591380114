import { useEffect, useState } from 'react';

export const useLastVisitedArea = () => {
  const [lastVisitedArea, setLastVisitedArea] = useState<string | null>();

  useEffect(() => {
    setLastVisitedArea(localStorage.getItem('lastVisitedArea'));
  }, []);

  return lastVisitedArea;
};
