import React, { useRef, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { DropdownBox } from '@components/navigation/DropdownBox/DropdownBox';
import { Button, ButtonTheme } from '@components/general/Button/Button';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';
import { useWindowSize } from '@hooks/useWindowSize';

import { PopOverMobile } from './PopOverMobile/PopOverMobile';
import styles from './PopOver.module.scss';

type Props = {
  theme?: 'dark' | 'light';
  title?: string;
  description?: string | React.ReactNode;
  className?: ClassValue;
  dropDownBoxClassContainer?: ClassValue;
  disable?: boolean;
  fixed?: boolean;
  showIcon?: boolean;
  position?: 'bottom' | 'top' | 'left' | 'right';
  width?: number | 'auto';
  showCallToActionButton?: boolean;
  disableHoverEvents?: boolean;
  boxOffSetManual?: number;
  isShowHighlightActivated?: boolean;
  callToActionText?: string; // Text to be displayed on Call-To-Action button
  showPopover?: boolean; // Used for externally display the popOver
  showPopoverString?: string; // Used to set if the popover shows or not with a string
  onClickCallToAction?: (event: React.MouseEvent) => void;
  boxClassName?: ClassValue;
  callToActionClass?: ClassValue;
  callToActionTheme?: ButtonTheme;
  showArrow?: boolean;
  onClickOut?: () => void;
  clickChildrenMobile?: boolean;
  dataTestId?: string;
  dataGuideTourId?: string;
  children?: React.ReactNode;
};

type PropsIcon = {
  isShowHighlightActivated?: boolean;
  theme?: 'dark' | 'light';
  onClick?: () => void;
};

export const InfoIconCircle = () => (
  <img src="/images/icons/Info-icon-2.svg" alt="icon info" width="13.945" height="13.945" />
);

export const InfoIconForward = React.forwardRef<HTMLSpanElement, PropsIcon>(
  ({ isShowHighlightActivated, theme, onClick }, ref) => (
    <span onClick={onClick} ref={ref} className={clsx(styles.Info, isShowHighlightActivated && styles.InfoActivated)}>
      {theme === 'dark' && <InfoIconCircle />}
    </span>
  ),
);

export const PopOver: React.FC<Props> = ({
  title,
  theme = 'dark',
  description,
  className,
  disable,
  showIcon,
  position = 'top',
  width = 240,
  showCallToActionButton = false,
  disableHoverEvents = false,
  callToActionText = '',
  onClickCallToAction,
  isShowHighlightActivated,
  showPopover = false,
  showPopoverString = 'false',
  children,
  boxClassName,
  dropDownBoxClassContainer,
  callToActionTheme = 'white',
  callToActionClass,
  showArrow = true,
  fixed,
  boxOffSetManual,
  onClickOut,
  clickChildrenMobile = false,
  dataTestId,
  dataGuideTourId,
}) => {
  if (disable) return <div className={clsx(className)}>{children}</div>;

  const [showPopoverInternally, setShowPopoverInternally] = React.useState(false);
  const popOverRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);
  const timeRef = useRef<NodeJS.Timeout>();
  const [open, setOpen] = useState(false);
  const windowsObject = useWindowSize();
  const windowsWidth = Number(windowsObject?.width);
  let showPopoverV2 = false;

  if (showPopoverString === 'true') {
    showPopoverV2 = true;
  } else if (showPopoverString === 'false') {
    showPopoverV2 = false;
  }

  const openPopOver = () => {
    setOpen(true);
  };

  const closePopOver = () => {
    setOpen(false);
  };

  useOutsideAlerter('data-text', () => {
    setTimeout(closePopOver);
  });

  const onEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.type === 'mouseenter') {
      if (timeRef.current) clearTimeout(timeRef.current);
      timeRef.current = setTimeout(() => {
        setShowPopoverInternally(!disableHoverEvents);
        if (timeRef.current) clearTimeout(timeRef.current);
      }, 400);
    }
    if (event.type === 'mouseleave') {
      if (timeRef.current) clearTimeout(timeRef.current);
      timeRef.current = setTimeout(() => {
        setShowPopoverInternally(false);
      }, 200);
    }
  };

  const onClickOutWrapper = () => {
    if (onClickOut) onClickOut();
  };

  return (
    <>
      <div
        className={clsx(styles.Container, className)}
        onMouseEnter={onEvent}
        onMouseLeave={onEvent}
        ref={popOverRef}
        data-test-id={dataTestId}
        data-guide-tour-id={dataGuideTourId}
      >
        {!clickChildrenMobile && children}
        {showIcon && (
          <InfoIconForward
            data-text
            theme={theme}
            isShowHighlightActivated={isShowHighlightActivated}
            ref={iconRef}
            onClick={openPopOver}
          />
        )}
        {clickChildrenMobile && (
          <div className={styles.Dotten} onClick={openPopOver}>
            {children}
          </div>
        )}
      </div>
      {windowsWidth < 1281 ? (
        <PopOverMobile isOpen={open}>
          <div style={{ width: windowsWidth, padding: 10 }}>
            {title && <div className={styles.Title}>{title}</div>}
            {description && <div className={styles.Description}>{description}</div>}
            {showCallToActionButton && (
              <Button
                className={clsx(styles.CallToActionButton, callToActionClass)}
                theme={callToActionTheme}
                onClick={onClickCallToAction}
              >
                {callToActionText}
              </Button>
            )}
          </div>
        </PopOverMobile>
      ) : (
        <DropdownBox
          className={clsx(styles.DropdownBox, boxClassName)}
          padding="small"
          isOpen={
            showIcon
              ? open || showPopoverInternally || showPopover || showPopoverV2
              : showPopoverInternally || showPopover || showPopoverV2
          }
          target={showIcon ? iconRef : popOverRef}
          position={position}
          onMouseEnter={onEvent}
          onMouseLeave={onEvent}
          dropDownBoxClassContainer={dropDownBoxClassContainer}
          theme={theme}
          showArrow={showArrow}
          onClickOut={onClickOutWrapper}
          fixed={fixed}
          boxOffSetManual={boxOffSetManual}
        >
          <div style={{ width }}>
            {title && <div className={styles.Title}>{title}</div>}
            {description && <div className={styles.Description}>{description}</div>}
            {showCallToActionButton && (
              <Button
                className={clsx(styles.CallToActionButton, callToActionClass)}
                theme={callToActionTheme}
                onClick={onClickCallToAction}
              >
                {callToActionText}
              </Button>
            )}
          </div>
        </DropdownBox>
      )}
    </>
  );
};
