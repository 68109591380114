import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { DropdownMenu } from '@components/navigation/DropdownMenu/DropdownMenu';
import { Position } from '@components/navigation/DropdownBox/DropdownBox';

import styles from './ListMenu.module.scss';

type Props = {
  className?: ClassValue;
  boxContainer?: ClassValue;
  containerListClass?: ClassValue;
  label: string;
  list: any;
  itemTitle: string;
  itemIcon: string;
  itemAction: string;
  onClickItem: (event: string) => void;
  onClickOut?: () => void;
  toggleShowOptions: () => void;
  showOptions: boolean;
  showArrow?: boolean;
  position?: Position;
  uppercase?: boolean;
  iconType?: 'url' | 'text';
  dataTestId?: string;
  dataTestIdCurrency?: string;
  isCurrencySelector?: boolean;
  isHelpMenu?: boolean;
};

type Item = Pick<Props, 'itemIcon' | 'itemTitle' | 'itemAction'>;

export const ListMenu: React.FC<Props> = ({
  className,
  list,
  label,
  itemTitle,
  itemIcon,
  itemAction,
  boxContainer,
  containerListClass,
  onClickItem,
  onClickOut,
  toggleShowOptions,
  showOptions,
  showArrow,
  position,
  uppercase,
  iconType,
  dataTestId,
  dataTestIdCurrency,
  isCurrencySelector,
  isHelpMenu,
}) => (
  <DropdownMenu
    theme={dataTestIdCurrency ? 'white' : isHelpMenu ? 'HelpMenuLightGrayTheme' : 'lightGray'}
    label={label}
    className={clsx(className)}
    isActive={showOptions}
    onClick={toggleShowOptions}
    boxClassName={boxContainer}
    onClickOut={onClickOut}
    showArrow={showArrow}
    position={position}
    dataTestId={dataTestId}
    dataTestIdCurrency={dataTestIdCurrency}
  >
    <ul className={clsx(styles.Container, containerListClass, uppercase && styles.Uppercase)}>
      {list.map(
        (item: Item) =>
          item && (
            <li
              key={item[itemTitle as keyof typeof item]}
              className={item[itemAction as keyof typeof item] === 'open_guided_tour' ? 'guided_tour_button' : ''}
            >
              <div
                data-test-id={item[itemIcon as keyof typeof item]}
                onClick={() => onClickItem(item[itemAction as keyof typeof item])}
                className={clsx(isCurrencySelector && styles.CurrencySelectorDiv)}
              >
                {iconType === 'text' && <span className={styles.ItemIcon}>{item[itemIcon as keyof typeof item]}</span>}
                {iconType === 'url' && (
                  <img
                    className={styles.ItemIcon}
                    src={item[itemIcon as keyof typeof item]}
                    alt={item[itemTitle as keyof typeof item]}
                  />
                )}
                <span className={clsx(isCurrencySelector && styles.CurrencySelectorSpan)}>
                  {item[itemTitle as keyof typeof item]}
                </span>
              </div>
            </li>
          ),
      )}
    </ul>
  </DropdownMenu>
);
