import { useMutation, useQuery } from '@apollo/client';
import { GET_FOLLOW_AREAS, FollowAreas, FollowArea } from '@apolloCli/queries/followAreas';
import { ADD_FOLLOW_AREA, DELETE_FOLLOW_AREA } from '@apolloCli/mutations/client';
import { orderAreasAlphabetical } from '@utils/orderAreasAlphabetical';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';

import { useUser } from './useUser';

type Args = {
  skip?: boolean;
};

export const useFollowAreas = ({ skip }: Args = {}) => {
  const { activeOrdersV2, loading: loadingActiveV2, isGodmodeOrHasGlobalPlan } = useAreaActiveOrdersV2();
  const { isLogin } = useUser();

  const [mutateAddFollowArea, { error: mutationAddError }] = useMutation(ADD_FOLLOW_AREA);

  const [mutateRemoveFollowArea, { error: mutationDeleteError }] = useMutation(DELETE_FOLLOW_AREA);

  const addFollowArea = async (id: number) => {
    const result = await mutateAddFollowArea({ variables: { id } });
    return result;
  };

  const deleteFollowArea = async (id: number) => {
    const result = await mutateRemoveFollowArea({ variables: { id } });
    return result;
  };

  const {
    data,
    loading: loadingFollow,
    error,
    refetch,
  } = useQuery<FollowAreas>(GET_FOLLOW_AREAS, {
    skip: !isLogin || skip,
  });

  const followAreasFiltered = (list: FollowArea[]) => {
    const idsV2 = activeOrdersV2?.areas.map((activeOrder) => activeOrder.areaId);
    const filtered = isGodmodeOrHasGlobalPlan
      ? list
      : list.filter((followArea) => !idsV2?.includes(followArea.area.id));
    return orderAreasAlphabetical(filtered);
  };
  const DEFAULT_FOLLOW_AREA =
    data?.followAreas && followAreasFiltered(data.followAreas).length > 0
      ? followAreasFiltered(data.followAreas)[0].area.id
      : undefined;

  return {
    addFollowArea,
    deleteFollowArea,
    followAreas: data?.followAreas ? followAreasFiltered(data.followAreas) : [],
    followAreasLoading: loadingFollow || loadingActiveV2,
    error: error || mutationAddError || mutationDeleteError,
    DEFAULT_FOLLOW_AREA,
    refetchFollowAreas: refetch,
  };
};
