import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Login } from '@layout/Dashboard/Login';
import { Box } from '@components/layout/Box/Box';
import { WizardState } from '@components/auth/LoginWizard/LoginWizard.interface';
import { useUser } from '@hooks/useUser';

import styles from './LoginContent.module.scss';

type Props = {
  type: 'analyze' | 'score' | 'competitors';
  mode?: 'fixed' | 'simple';
};

export const LoginContent: React.FC<Props> = ({ type, mode = 'fixed' }) => {
  const [state, setState] = useState<WizardState>({ step: 'signin' });
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const { user, isLogin, loading: userLoading, refetch } = useUser();

  const router = useRouter();
  const { ell } = router.query;

  const onStep = (newState: WizardState) => {
    setState(newState);
    if (newState.step === 'close') {
      setState({ step: 'signin' });
      setShowLogin(false);
    }
  };

  useEffect(() => {
    const userDescriptionType = !!user?.userDescriptionType;
    if (!isLogin && !userLoading) {
      setShowLogin(true);
    } else if (isLogin && !userDescriptionType && !userLoading && user) {
      setState({ step: 'profiles' });
      setShowLogin(true);
    }
  }, [isLogin, userLoading, user, ell]);

  // Detect logout to reset state
  useEffect(() => {
    if (!isLogin) {
      setState({ step: 'signin' });
    }
  }, [isLogin]);

  // Refetch the user if the user is logged in but the user is not fetched
  useEffect(() => {
    if (isLogin && !user && !userLoading) {
      refetch();
    }
  }, [isLogin, user, userLoading, refetch]);

  return showLogin ? (
    <div className={clsx(styles.LoginContentUpdate, styles[type], styles[mode])}>
      {state.step === 'signin' ? (
        <Login loginType={type} state={state} onStep={onStep} />
      ) : (
        <Box>
          <Login loginType={type} state={state} onStep={onStep} />
        </Box>
      )}
    </div>
  ) : null;
};
