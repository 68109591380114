import { DEFAULT_CITY_ID } from 'src/const/dashboard';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';

import { useFollowAreas } from './useFollowAreas';

type Args = {
  skip?: boolean;
};

export const useDefaultArea = ({ skip }: Args = {}) => {
  const { DEFAULT_ACTIVE_AREA_V2, loading: loadingV2 } = useAreaActiveOrdersV2({ skip });
  const { DEFAULT_FOLLOW_AREA, followAreasLoading } = useFollowAreas({ skip });
  const DEFAULT_ACTIVE_AREA = DEFAULT_ACTIVE_AREA_V2;

  return {
    loading: loadingV2 || followAreasLoading,
    DEFAULT_AREA_ID: DEFAULT_ACTIVE_AREA || DEFAULT_FOLLOW_AREA || DEFAULT_CITY_ID,
  };
};
