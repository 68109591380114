import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useReactiveVar } from '@apollo/client';
import { useUiNotifications, removeNotifications } from '@hooks/useUiNotifications';
import { notificationPositionBottomLeftVar } from '@apolloCli/policies/uiPolicy';
import clsx from 'clsx';
import { Notification } from '@components/feedback/Notification/Notification';

import styles from './NotificationCenter.module.scss';

const list = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const NotificationCenter: React.FC = () => {
  const { notifications } = useUiNotifications();
  const notificationPositionBottomLeft = useReactiveVar(notificationPositionBottomLeftVar);

  return (
    <ul className={clsx(styles.Container, notificationPositionBottomLeft && styles.BottomLeft)}>
      <AnimatePresence>
        {notifications.map((item) => (
          <motion.li
            className={styles.Item}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={list}
            key={item.id}
          >
            <Notification
              type={item.type}
              title={item.title}
              description={item.description}
              onClickClose={() => removeNotifications(item.id)}
            />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
};
