import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { User } from '@interfaces/User';
import { appEvent } from '@utils/analytics';
import { Button } from '@components/general/Button/Button';
import { Radio } from '@components/dataEntry/Radio/Radio';

import { StepProps } from '../LoginWizard.interface';

import styles from './DescriptionSelector.module.scss';

export type DescriptionType = User['userDescriptionType'];

const namedDescriptionType: NamedDescriptionType = {
  vr_host: 'I currently host guests',
  potential_vr_host: 'I am considering listing my property as a vacation rental',
  vr_investor: 'I am researching markets where I can buy a property to list as a vacation rental',
  property_manager: 'I professionally manage vacation rentals',
  real_estate: 'I work in real estate',
  other: 'Other',
};

type NamedDescriptionType = {
  [key in DescriptionType]: string;
};

type DescriptionTypeSelectorProps = StepProps & {
  onClickDescriptionSave?: (selectedDescriptionType: DescriptionType) => void;
};

export const DescriptionSelector: React.FC<DescriptionTypeSelectorProps> = ({ next, onClickDescriptionSave }) => {
  const [selectedDescription, setSelectedDescription] = useState<DescriptionType>();
  const [showError, setShowError] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const { pathname } = useRouter();

  const isValidDescriptionType = (descriptionType?: DescriptionType) => !!descriptionType;

  const onClickSaveInner = async () => {
    if (!isValidDescriptionType(selectedDescription)) {
      setShowError(true);
      return;
    }

    if (selectedDescription && onClickDescriptionSave) {
      onClickDescriptionSave(selectedDescription);
      appEvent('Complete User Type Modal', {
        onPage: pathname,
      });
    }

    next({ step: 'close' });
  };

  useEffect(() => {
    appEvent('Guide Tours Modal Opened', {
      onPage: pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDescription = (key: DescriptionType) => {
    setSelectedDescription(key);
    setShowError(!isValidDescriptionType(key));
    setSelectedAnswer(namedDescriptionType[key]);
  };

  return (
    <div className={styles.ProfilesSelector}>
      <div className={styles.Header}>
        <img src="/images/welcome-banner.png" alt="welcome banner" />
        <span>We're so glad you are here! Before you start exploring we want to get to know you better.</span>
        <p>Which one of these options best describes you?</p>
      </div>
      {Object.keys(namedDescriptionType).map((key) => (
        <div key={namedDescriptionType[key]} className={styles.UserTypeOptions}>
          <Radio
            theme="default"
            label={namedDescriptionType[key]}
            checked={namedDescriptionType[key] === selectedAnswer}
            onChange={() => onClickDescription(key)}
          />
        </div>
      ))}
      {showError && (
        <div className={styles.ErrorMessage}>
          <img src="/images/icons/error.svg" />
          Select at least one option to continue
        </div>
      )}
      <Button
        className={clsx(styles.Button, !isValidDescriptionType(selectedDescription) && styles.Disable)}
        theme="default"
        locked={!isValidDescriptionType(selectedDescription)}
        onClick={onClickSaveInner}
      >
        Start!
      </Button>
    </div>
  );
};
