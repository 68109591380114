/* eslint-disable max-len */
import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './LockedIcon.module.scss';

type Props = {
  className?: ClassValue;
  size?: 'small';
  color?: 'blue';
  onClick?: () => void;
};

export const LockedIcon: React.FC<Props> = ({ className, size, color, onClick }) => {
  if (size === 'small') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.369"
        height="12.634"
        className={clsx(styles.Small, color === 'blue' && styles.SmallBlue)}
      >
        <g data-name="Group 4773" fill="#fff">
          <g data-name="Path 1936">
            <path d="M1.369 5.17v-.888C1.369 2.197 3.305.5 5.685.5S10 2.197 10 4.282v.047l.005.836H9.78l-1.787-.002c-2.248 0-5.41.005-6.624.007z" />
            <path
              d="M5.685 1C3.58 1 1.869 2.472 1.869 4.282v.387a4446.967 4446.967 0 017.634-.005L9.5 4.332v-.05C9.5 2.472 7.788 1 5.684 1m0-1C8.345 0 10.5 1.917 10.5 4.282c0 .02.01 1.389 0 1.389a169.82 169.82 0 00-2.507-.008c-2.854 0-7.124.008-7.124.008V4.282C.869 1.917 3.025 0 5.685 0z"
              className={styles.Fill}
            />
          </g>
          <g data-name="Rectangle 1362" className={styles.Stroke}>
            <path stroke="none" d="M0 4.816h11.369v7.818H0z" />
            <path fill="none" d="M.5 5.316h10.369v6.818H.5z" />
          </g>
          <path data-name="Path 2294" d="M5.658 7.694v2.217" className={styles.Stroke} />
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
      width="13.043"
      height="16"
      fill="none"
      stroke="#a8aaae"
      strokeWidth="1.3"
      onClick={onClick}
    >
      <path d="M.65 6.648h11.743v8.7H.65z" />
      <path d="M6.678 9.314v3.625m-5.013-6.29c0-3.33 1.353-6 4.666-6s5.032 2.328 5.032 6" />
    </svg>
  );
};
