import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { User } from '@interfaces/User';
import { appEvent } from '@utils/analytics';
import { ITEMS, TourItem } from '@pageComponents/ShareComponents/ToursModal/items';
import { useUser } from '@hooks/useUser';

import { StepProps, WizardState } from '../LoginWizard.interface';

import styles from './TourDescSelector.module.scss';

export type DescriptionType = User['userDescriptionType'];

type TourDescSelectorProps = StepProps & {
  onClickDescriptionSave?: (selectedDescriptionType: DescriptionType) => void;
  next?: (step: WizardState) => void;
  isLoginStep?: boolean;
  skip?: () => void;
};

const DEFAULT_USER_TYPE = 'other';

export const TourDescSelector = ({
  next,
  onClickDescriptionSave,
  isLoginStep,
  skip = () => {},
}: TourDescSelectorProps) => {
  const { pathname } = useRouter();
  const { user } = useUser();

  const onClickSaveInner = async (key: string) => {
    if (key && onClickDescriptionSave) {
      onClickDescriptionSave(key);
      appEvent('Complete User Type Modal', {
        onPage: pathname,
      });
    }

    if (isLoginStep) {
      next({ step: 'close' });
    }
  };

  const handleClick = async (item: TourItem) => {
    await onClickSaveInner(item.key);
    item.action();
  };

  const onSkip = () => {
    if (isLoginStep) {
      if (!user?.userDescriptionType && onClickDescriptionSave) {
        onClickDescriptionSave(DEFAULT_USER_TYPE);
        appEvent('Complete User Type Modal', {
          onPage: pathname,
        });
      }
      next({ step: 'close' });
    } else {
      skip();
    }
  };

  useEffect(() => {
    if (isLoginStep) {
      appEvent('Open User Type Modal', {
        onPage: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginStep]);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <img src="/images/welcome-banner.png" alt="welcome banner" />
        <p>
          We're so glad you are here! <br /> Here you can find guided tours to help you accomplish what you need
        </p>
      </div>
      <div className={styles.Items}>
        {ITEMS.map((item) => (
          <div onClick={() => handleClick(item)}>
            <img src={item.icon} />
            {item.desc}
          </div>
        ))}
      </div>
      <div className={styles.Footer} onClick={onSkip}>
        I just want to explore the tool my way
      </div>
    </div>
  );
};
