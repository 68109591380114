import { getAxios } from '@utils/axios';
import { isLoginModalOpenVar, resetStore } from '@apolloCli/policies/uiPolicy';

export const useLogin = () => {
  const logOut = async () => {
    const response = await fetch('/api/impersonation/clearCookie', {
      method: 'POST',
    });
    if (response.status >= 200 && response.status < 300) {
      resetStore();
    }
  };

  const logIn = () => {
    isLoginModalOpenVar(true);
  };

  return { logIn, logOut };
};
