import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery, useReactiveVar } from '@apollo/client';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayjs from 'dayjs';
import { DatesRange, DatesRangesInfo, GET_DATES_RANGES } from '@apolloCli/queries/datesRanges';
import { useAreaInfo } from '@hooks/useAreaInfo';
import {
  addHoursToTimestamp,
  YEAR_MONTH_DAY_FORMAT,
  LAST_4_WEEKS,
  LAST_3_MONTHS,
  LAST_12_MONTHS,
  // MONTH_TO_DATE,
  // QUARTER_TO_DATE,
  // YEAR_TO_DATE,
  RangeTypes,
  DEFAULT_DATARANGE,
  DEFAULT_DATARANGE_WEEKLY,
  LAST_4_YEARS,
} from '@utils/dates';
import { defaulDateRangeVar, filterProviderTypeVar } from '@apolloCli/policies/uiPolicy';
import { PlanType } from '@apolloCli/queries/activeOrders';

export const getSelectedDateRange = (range: RangeTypes, minDate: number, maxDate: number) => {
  const today = dayjs.unix(maxDate);

  const until = today.format(YEAR_MONTH_DAY_FORMAT);
  const defaultStart = today.subtract(11, 'month').startOf('month');
  let since;
  switch (range) {
    case LAST_4_WEEKS:
      since = today.startOf('month');
      break;
    case LAST_3_MONTHS:
      since = today.subtract(2, 'month').startOf('month');
      break;
    case LAST_12_MONTHS:
      since = defaultStart;
      break;
    // hide until we get weekly update data.
    // case MONTH_TO_DATE:
    //   since = today.startOf('month');
    //   break;
    // case QUARTER_TO_DATE:
    //   since = today.startOf('quarter');
    //   break;
    // case YEAR_TO_DATE:
    //   since = today.startOf('year');
    //   break;
    case LAST_4_YEARS:
      since = today.subtract(4, 'years');
      break;
    default:
      since = defaultStart;
  }
  since = since.format(YEAR_MONTH_DAY_FORMAT);
  return { until, since };
};

export const getSelectedDateRangeWeekly = (range: RangeTypes, minDate: number, maxDate: number) => {
  const today = dayjs.unix(maxDate);

  const until = today.format(YEAR_MONTH_DAY_FORMAT);
  const defaultStart = today.subtract(12, 'month');
  const isLastMonthDay = today.format(YEAR_MONTH_DAY_FORMAT) === today.endOf('month').format(YEAR_MONTH_DAY_FORMAT);
  let since;
  switch (range) {
    case LAST_4_WEEKS:
      since = isLastMonthDay ? today.startOf('month') : today.subtract(1, 'month');
      break;
    case LAST_3_MONTHS:
      since = today.subtract(3, 'month');
      break;
    case LAST_12_MONTHS:
      since = defaultStart;
      break;
    // hide until we get weekly update data.
    // case MONTH_TO_DATE:
    //   since = today.startOf('month');
    //   break;
    // case QUARTER_TO_DATE:
    //   since = today.startOf('quarter');
    //   break;
    // case YEAR_TO_DATE:
    //   since = today.startOf('year');
    //   break;
    case LAST_4_YEARS:
      since = today.subtract(4, 'years');
      break;
    default:
      since = defaultStart;
  }

  since = since.format(YEAR_MONTH_DAY_FORMAT);
  return { until, since };
};

export const getDefaultDateRange = (planType: PlanType | undefined, minDate: number, maxDate: number) => {
  const today = dayjs.unix(maxDate);
  const until = today.format(YEAR_MONTH_DAY_FORMAT);
  const defaultStart = today.subtract(12, 'month');
  const isLastMonthDay = today.format(YEAR_MONTH_DAY_FORMAT) === today.endOf('month').format(YEAR_MONTH_DAY_FORMAT);
  let since;
  if (planType && planType !== 'FREE') {
    const isBasic = planType === 'BASIC';
    if (isBasic) {
      since = today.subtract(3, 'month');
    } else {
      since = defaultStart;
    }
  } else {
    since = isLastMonthDay ? today.startOf('month') : today.subtract(1, 'month');
  }

  since = since.format(YEAR_MONTH_DAY_FORMAT);
  return { since, until };
};

type DateRangesOptions = {
  skip?: boolean;
  type?: string;
};

const defaultOptions: DateRangesOptions = {
  skip: false,
  type: 'stats',
};

const tzOffset = new Date().getTimezoneOffset() / -60;

// asTZUnixTs converts a UTC unix timestamp to one in the timezone of the local computer
const asTZUnixTs = (timestamp: number) => addHoursToTimestamp(timestamp, tzOffset);

const asUTCDatesRange = (datesRange?: DatesRange) => {
  if (!datesRange) return datesRange;

  return ['minDate', 'maxDate'].reduce(
    (tzRange, prop) => {
      // eslint-disable-next-line no-param-reassign
      (tzRange as any)[prop] = asTZUnixTs((datesRange as any)[prop]);
      return tzRange;
    },
    { ...datesRange } as DatesRange,
  );
};

dayjs.extend(quarterOfYear);

const asUTCTimestamp = (isoDatePart?: string) => (isoDatePart ? dayjs(`${isoDatePart}T00:00:00.000Z`) : dayjs()).unix();
const minDateCapUnix = process.env.ANALYTICSDASH_MIN_DATE_CAP
  ? asTZUnixTs(asUTCTimestamp(process.env.ANALYTICSDASH_MIN_DATE_CAP))
  : 0;
const maxDateCapUnix = asTZUnixTs(asUTCTimestamp(process.env.ANALYTICSDASH_DATE_CAP));

const getMinMaxDate = (data: DatesRange[], provider: string) => {
  const dateRange = asUTCDatesRange(data.find((dateRangeItem) => dateRangeItem.providerId === provider));

  const aMinDate = dateRange?.minDate ?? minDateCapUnix;
  const aMaxDate = dateRange?.maxDate ? Math.min(dateRange.maxDate, maxDateCapUnix) : maxDateCapUnix;

  return [aMinDate, aMaxDate];
};

defaulDateRangeVar(getSelectedDateRange(DEFAULT_DATARANGE, minDateCapUnix, maxDateCapUnix));

// The date ranges (both from envvars and external services) indicate the min and max
// UTC dates for which data is available, inclusive.
export const useDateRanges = (options: DateRangesOptions = defaultOptions) => {
  const { skip, type } = options;
  const router = useRouter();
  const { slug } = router.query;
  // if slug exist then use AreaInfo, else use DatesRanges
  const isValidSlug = slug ? !Number.isNaN(Number(slug)) : false;
  const { data: dataDatesRangesQuery, loading } = useQuery<DatesRangesInfo>(GET_DATES_RANGES, {
    variables: {
      type,
    },
    skip: isValidSlug || skip,
  });
  const { datesRanges: datesRangesAreaInfo } = useAreaInfo({
    skip: !isValidSlug || skip,
  });

  const [provider] = useReactiveVar(filterProviderTypeVar);
  const [minDate, setMinDate] = useState<number>(minDateCapUnix);
  const [maxDate, setMaxDate] = useState<number>(maxDateCapUnix);
  const [pipeRunDate, setPipeRunDate] = useState<number>(maxDateCapUnix);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (dataDatesRangesQuery || datesRangesAreaInfo) {
      let aMinDate = minDateCapUnix;
      let aMaxDate = maxDateCapUnix;
      let aPipeRunDate = maxDateCapUnix;

      if (dataDatesRangesQuery) {
        [aMinDate, aMaxDate] = getMinMaxDate(dataDatesRangesQuery.datesRanges, provider);
      }

      if (datesRangesAreaInfo) {
        [aMinDate, aMaxDate] = getMinMaxDate(datesRangesAreaInfo, provider);
      }

      aPipeRunDate = aMaxDate;

      // We make data public in full-months batches. Thus, use the last day of the
      // previous month when max date is not the last day of its month.
      if (
        dayjs.unix(aMaxDate).format(YEAR_MONTH_DAY_FORMAT) !==
        dayjs.unix(aMaxDate).endOf('month').format(YEAR_MONTH_DAY_FORMAT)
      ) {
        aMaxDate = dayjs.unix(aMaxDate).subtract(1, 'month').endOf('month').unix();
      }

      setMinDate(aMinDate);
      setMaxDate(aMaxDate);
      setPipeRunDate(aPipeRunDate);
      defaulDateRangeVar(getSelectedDateRangeWeekly(DEFAULT_DATARANGE_WEEKLY, minDate, pipeRunDate));
      setIsReady(true);
    }
  }, [dataDatesRangesQuery, datesRangesAreaInfo, minDate, maxDate, provider, pipeRunDate]);

  return {
    isReady,
    minDate,
    maxDate,
    pipeRunDate,
    loading,
    defaultDateRange: defaulDateRangeVar(),
  };
};
