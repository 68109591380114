import {
  memo, useEffect, useRef, useState, ReactNode,
} from 'react';
import { createPortal } from 'react-dom';

type Props = {
  id?: string;
  children?: ReactNode;
}

const Portal: React.FC<Props> = ({ id, children }) => {
  const el = useRef(
    (id && document.getElementById(id)) || document.createElement('div'),
  );
  const [dynamic] = useState(!el.current.parentElement);

  useEffect(() => {
    const { current } = el;
    if (dynamic) {
      if (id) current.id = id;
      document.body.appendChild(current);
    }
    return () => {
      if (dynamic && current.parentElement) {
        current.parentElement.removeChild(current);
      }
    };
  }, [dynamic, id]);
  return createPortal(children, el.current);
};

const MemoPortal = memo(Portal);

export { MemoPortal as Portal };
