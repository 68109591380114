import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { CloseButton } from '@components/general/CloseButton/CloseButton';
import { NotificationItem } from '@interfaces/UI';

import styles from './Notification.module.scss';

type Props = {
  onClickClose?: () => void;
  title: string;
  description?: string | ReactNode;
  type: NotificationItem['type'];
  children?: React.ReactNode;
  hideCloseButton?: boolean;
};

export const Notification: React.FC<Props> = ({
  onClickClose,
  title,
  description,
  type,
  children,
  hideCloseButton = false,
}) => (
  <div className={clsx(styles.Container, styles[type], hideCloseButton && styles.ContainerHideButton)}>
    <div>
      <div className={styles.Title}>{title}</div>
      {description && <div className={styles.Description}>{description}</div>}

      <div>{children}</div>
    </div>

    {!hideCloseButton && (
      <div className={styles.Close} onClick={onClickClose}>
        <CloseButton className={styles.CloseButton} />
      </div>
    )}
  </div>
);
