export const CURRENCIES = {
  USD: {
    name: 'US dollar',
    code: 'USD',
    symbol: '$',
  },
  GBP: {
    name: 'Pounds sterling',
    code: 'GBP',
    symbol: '£',
  },
  EUR: {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  CAD: {
    name: 'Canadian dollars',
    code: 'CAD',
    symbol: '$',
  },
  INR: {
    name: 'Indian rupee',
    code: 'INR',
    symbol: '₹',
  },
  AUD: {
    name: 'Australian dollars',
    code: 'AUD',
    symbol: '$',
  },
  MXN: {
    name: 'Mexican peso',
    code: 'MXN',
    symbol: '$',
  },
  BRL: {
    name: 'Brazilian real',
    code: 'BRL',
    symbol: 'R$',
  },
  ZAR: {
    name: 'South african rand',
    code: 'ZAR',
    symbol: 'R',
  },
  COP: {
    name: 'Colombian Peso',
    code: 'COP',
    symbol: '$',
  },
  ARS: {
    name: 'Argentine Peso',
    code: 'ARS',
    symbol: '$',
  },
  CLP: {
    name: 'Chilean Peso',
    code: 'CLP',
    symbol: '$',
  },
  PEN: {
    name: 'Peruvian Nuevo Sol',
    code: 'PEN',
    symbol: 'S/',
  },
  JMD: {
    name: 'Jamaican Dollar',
    code: 'JMD',
    symbol: '$',
  },
  NZD: {
    name: 'New Zealand Dollar',
    code: 'NZD',
    symbol: 'NZ$',
  },
};

export const CurrenciesMetrics = ['adr_booked', 'adj_revpal', 'gross_revenue', 'adj_revpan'];
