import gql from 'graphql-tag';
import { DatesRange } from '@apolloCli/queries/datesRanges';

export type AreaInfo = {
  areaType: string;
  id: number;
  name: string;
  fullName: string | null;
  nListings?: number;
  cityId?: number;
  city?: string;
  stateId?: number;
  state?: string;
  countryId?: number;
  country?: string;
};

export type AreaPolygon = {
  id: number;
  coordinates: any[];
};

export type GetAreaPolygon = {
  areas: AreaPolygon[];
};

export type GetAreaInfo = {
  areas: AreaInfo[];
  datesRanges?: DatesRange[];
};

export type MatchedArea = {
  exact: AreaInfo | null;
  related: AreaInfo[];
};

export type GetMatchAreas = {
  matchAreas: MatchedArea[];
};

export type GeocodedResult = {
  point: {
    lat: number;
    lon: number;
  };
};

export const GET_AREA = gql`
  query getAreaInfo($areaId: Int!, $withDatesRanges: Boolean = false) {
    areas(areaId: $areaId) {
      id
      name
      fullName
      areaType
      cityId
      city
      stateId
      state
      countryId
      country
    }
    datesRanges @include(if: $withDatesRanges) {
      providerId
      minDate
      maxDate
    }
  }
`;

export const GET_AREA_POLYGON = gql`
  query getSupplyData($areaId: Int!) {
    areas(areaId: $areaId) {
      id
      coordinates
    }
  }
`;

export const GET_MATCH_AREAS = gql`
  query getMatchAreas($geocodedResults: [GeocodedResult!]!, $radius: Int, $limit: Int, $includeAreaType: Boolean!) {
    matchAreas(geocodedResults: $geocodedResults, radius: $radius, limit: $limit) {
      exact {
        id
        name
        fullName
        areaType @include(if: $includeAreaType)
      }
      related {
        id
        fullName
        nListings
        areaType @include(if: $includeAreaType)
      }
    }
  }
`;
