import React, { useRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import { DropdownButton } from '@components/navigation/DropdownMenu/DropdownButton/DropdownButton';
import { DropdownBox, Position } from '@components/navigation/DropdownBox/DropdownBox';
import { ButtonTheme } from '@components/general/Button/Button';

import styles from './DropdownMenu.module.scss';

type Props = {
  locked?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  hasChanges?: boolean;
  label: string | React.ReactNode;
  onClick: () => void;
  onClickOut?: () => void;
  hideChevron?: boolean;
  theme?:
    | 'gray'
    | 'inverted'
    | 'collapse-mobile'
    | 'lightGray'
    | 'smallGray'
    | 'lightTextGray'
    | 'custom'
    | 'white'
    | 'HelpMenuLightGrayTheme'
    | 'blue'
    | 'whiteBlue';
  className?: ClassValue;
  boxClassName?: ClassValue;
  position?: Position;
  showArrow?: boolean;
  chevronClass?: ClassValue;
  popOverDescription?: string | React.ReactNode;
  customLabel?: string | React.ReactNode;
  popOverClass?: ClassValue;
  popOverDisable?: boolean;
  popOverPosition?: 'bottom' | 'top' | 'left' | 'right';
  popOverBoxClassName?: ClassValue;
  showPopover?: boolean;
  popOverShowCallToActionButton?: boolean;
  popOverCallToActionTheme?: ButtonTheme;
  popOverCallToActionClass?: ClassValue;
  dropdownMenuContainer?: ClassValue;
  popOverCallToActionText?: string;
  popOverOnClickCallToAction?: () => void;
  dataTestIdCurrency?: string;
  dataTestId?: string;
  dataGuideTourId?: string;
  children: React.ReactNode;
};

export const DropdownMenu: React.FC<Props> = ({
  locked,
  isActive,
  isSelected,
  hasChanges,
  label,
  onClick,
  onClickOut,
  children,
  hideChevron,
  theme,
  className,
  boxClassName,
  position,
  showArrow,
  chevronClass,
  popOverDescription,
  popOverClass,
  popOverDisable,
  popOverPosition,
  popOverBoxClassName,
  showPopover,
  popOverShowCallToActionButton,
  popOverCallToActionTheme,
  popOverCallToActionClass,
  popOverCallToActionText,
  popOverOnClickCallToAction,
  dropdownMenuContainer,
  customLabel,
  dataTestId,
  dataTestIdCurrency,
  dataGuideTourId,
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <div className={clsx(styles.DropdownMenu, dropdownMenuContainer)}>
      <DropdownButton
        className={clsx(className)}
        locked={locked}
        isActive={isActive}
        isSelected={isSelected}
        hasChanges={hasChanges}
        label={label}
        onClick={onClick}
        hideChevron={hideChevron}
        theme={theme}
        ref={ref}
        chevronClass={chevronClass}
        popOverClass={popOverClass}
        popOverDescription={popOverDescription}
        popOverDisable={popOverDisable}
        popOverPosition={popOverPosition}
        popOverBoxClassName={popOverBoxClassName}
        showPopover={showPopover}
        popOverShowCallToActionButton={popOverShowCallToActionButton}
        popOverCallToActionTheme={popOverCallToActionTheme}
        popOverCallToActionClass={popOverCallToActionClass}
        popOverCallToActionText={popOverCallToActionText}
        popOverOnClickCallToAction={popOverOnClickCallToAction}
        customLabel={customLabel}
        dataTestId={dataTestId}
        dataTestIdCurrency={dataTestIdCurrency}
        dataGuideTourId={dataGuideTourId}
      />
      <DropdownBox
        position={position}
        showArrow={showArrow}
        isOpen={isActive}
        className={clsx(styles.DropdownBox, boxClassName)}
        target={ref}
        onClickOut={onClickOut}
      >
        {children}
      </DropdownBox>
    </div>
  );
};
