import React, { FC } from 'react';
import clsx from 'clsx';

import { headerParameters, TabOptions } from '../Header.logic';

import styles from './HeaderTab.module.scss';

type Props = {
  option: TabOptions;
  defaultArea: number;
};

export const HeaderTab: FC<Props> = ({ option, defaultArea }) => {
  const { generateHeaderRouter } = headerParameters();

  const onClickTabs = () => {
    generateHeaderRouter({ label: option.label, defaultArea });
  };

  return (
    <div className={clsx(styles.Item, option.isActive && styles.Active)} onClick={onClickTabs}>
      {option.label}
    </div>
  );
};
