import { useQuery } from '@apollo/client';
import { GET_LISTING_DETAILS, GetListingDetails, Listing } from '@apolloCli/queries/listings';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDateRanges } from '@hooks/useDateRanges';
import { YEAR_MONTH_DAY_FORMAT } from '@utils/dates';
import { orderScores } from '@utils/orderAreasAlphabetical';

import { useClerkToken } from './useClerkToken';

type Props = {
  uid?: string;
  trackedUid?: string;
  areaId?: number;
  providerId?: string;
  trackedProviderId?: string;
  nVrps?: number;
  isEnterprise?: boolean;
};

export const useListing = ({
  uid,
  areaId,
  providerId,
  trackedUid,
  trackedProviderId,
  isEnterprise = false,
}: Props = {}) => {
  const [listing, setListing] = useState<Listing>();

  const { token } = useClerkToken();

  const { isReady: isReadyDateRanges, maxDate: today } = useDateRanges();

  const { data, loading, error, refetch } = useQuery<GetListingDetails>(GET_LISTING_DETAILS, {
    context: {
      headers: {
        authorization: token && isEnterprise ? `Bearer ${token}` : '',
      },
    },
    variables: {
      uid,
      areaId,
      providerId,
      trackedUid,
      trackedProviderId,
      month: dayjs.unix(today).subtract(1, 'month').startOf('month').format(YEAR_MONTH_DAY_FORMAT),
    },
    skip:
      !providerId ||
      !uid ||
      !isReadyDateRanges ||
      providerId === 'undefined' ||
      uid === 'undefined' ||
      (isEnterprise && !token),
  });

  useEffect(() => {
    if (!loading && !error && data?.listing) {
      const copyListing = { ...data.listing };
      copyListing.scores = orderScores(data.listing.scores);
      setListing(copyListing);
    } else {
      setListing(undefined);
    }
  }, [data, error, loading]);

  return {
    listing,
    loading,
    error,
    refetchListing: refetch,
  };
};
